import React, { Component, useState } from 'react';
import { Navigate } from 'react-router-dom';
import PropTypes from 'prop-types';
import fetch from 'isomorphic-fetch';

import {
    Form,
    FormGroup,
    Label,
    Input,
    Container,
    Row,
    Col,
    Button,
    Alert
} from 'reactstrap';
import { useAlert } from "react-alert";
import ReactLoading from "react-loading";

const SendPassword = props => {
    const alert = useAlert()
    
    const [ state, setState ] = useState({
        username: '',
        redirectToReferrer: false,
        passwordResetSentLoginmessage: false,
        login: false,
        confirming: false
    });
    
    const postData = (url, data) => {
        return fetch(url, {
            body: data,
            cache: 'no-cache',
            credentials: 'same-origin',
            headers: {
                'user-agent': 'Mozilla/4.0 MDN Example',
                'content-type': 'application/x-www-form-urlencoded'
            },
            method: 'POST',
            mode: 'cors',
            redirect: 'follow',
            referrer: 'no-referrer',
        })
          .then(response => {
              if(response.status === 200) {
                  return response.json();
              } else if (response.status === 201) {
                  return true;
              }
              return false;
          });
    }
    
    const handleSendPassword = (event) => {
        setState(prevState => ({
            ...prevState,
            confirming: true
        }))
        event.preventDefault();
        
        // Fetch form values.
        let data = {
            username: state.username
        };
        
        let userInfoUrlEncoded = encodeURIComponent(data.username);
        
        postData('/api/user/' + userInfoUrlEncoded + '/password-email')
          .then(result => {
              console.log('password reset requested');
              console.log(result);
              if(result === false) {
                  props.setError('There was a problem sending the email');
                  alert.show('There was a problem sending the email', {
                      type: 'error'
                  })
    
              } else {
                  setState(prevState => ({ ...prevState, passwordResetSentLoginmessage: true, login: true}));
                  alert.show('An email has been sent to you to help you reset your password', {
                      type: 'success'
                  })
                  
              }
              
              setState(prevState => ({
                  ...prevState,
                  confirming: false
              }))
          });
    }
    
    const handleUsernameChange = (e) => {
        setState(prevState => ({
            ...prevState,
            username: e?.target?.value,
            invalidLoginmessage: false
        }));
    }
    
    const login = () => {
        setState(prevState => ({
            ...prevState,
            login: true
        }));
    }
    
    if (state.login) {
        return (
          <Navigate to='/' />
        );
    }
    
    return (
      <div>
          <Container style={{width:'500px', marginTop:'20px'}}>
          
              <Row>
                  <Col>
                      <h1>Reset Password</h1>
                  
                      <Form onSubmit={handleSendPassword} >
                          <FormGroup>
                              <Label for="loginUsername">Email address</Label>
                              <Input
                                type="text"
                                name="username"
                                id="loginUsername"
                                placeholder="your login email address"
                                value={state.username}
                                onChange={handleUsernameChange}
                              />
                          </FormGroup>
                          <Button type="button" outline color="secondary" className="float-left" onClick={() => login()}>Login</Button>
                          <Button type="submit" outline color="primary" className="float-right">
                              { state.confirming ? <ReactLoading type='spin' color='blue' height={25} width={15} />: 'Confirm'}
                          </Button>
                      </Form>
                  </Col>
              </Row>
      
          </Container>
      </div>
    );
};

export default SendPassword;
