import React, { useMemo } from "react"
import { Link } from "react-router-dom"
import { useSamplesQuery } from "api/getters"
import dayjs from "dayjs"
import utc from "dayjs/plugin/utc"
import TableContainer from "./TableContainer"
import { useTableQueryStore } from "stores/zustand/tableQueryStore"
dayjs.extend(utc)

export function SamplesTable({ customSelect = {}, permission, pageSize: pageSizeProp, ...rest }) {
  const { page, pageSize, selector, sort } = useTableQueryStore()
  const {
    data: samplesData,
    isLoading,
    isFetching,
    refetch,
  } = useSamplesQuery({
    variables: {
      sort,
      page,
      selector: {
        ...selector,
        ...customSelect,
      },
      pageSize: pageSizeProp || pageSize,
    },
  })

  const columns = useMemo(
    () => [
      {
        Header: "Sample ID",
        accessor: "id",
        hasSort: true,
        csvAccessor: "csvId",
      },
      {
        Header: "Patient ID",
        accessor: "subjectId",
        hasSort: true,
        csvAccessor: "csvSubjectId",
      },
      {
        Header: "Collected",
        accessor: "sampleCollectionDateTime",
        hasSort: true,
      },
      {
        Header: "Accessioned",
        accessor: "laboratoryReceivedDateTime",
        hasSort: true,
      },
      {
        Header: "Tube Type",
        accessor: "tubeType",
        hasSort: true,
      },
      {
        Header: "Collection Method",
        accessor: "collectionMethod",
        hasSort: true,
      },
      {
        Header: "Location",
        accessor: "sampleLocation",
        hasSort: true,
      },
      {
        Header: "Action",
        accessor: "action",
        skipCsv: true,
      },
    ],
    [],
  )

  const data = useMemo(
    () =>
      (samplesData &&
        samplesData.map((item) => ({
          id: item.id,
          csvId: item.id,
          subjectId: (
            <Link to={`/subject-info/${item.subjectId}/personal`} className="text-secondary" target="_blank">
              {item.subjectId}
            </Link>
          ),
          tubeType: item.tubeType,
          csvSubjectId: item.subjectId,
          sampleCollectionDateTime: item?.sampleCollectionDateTime && dayjs(item?.sampleCollectionDateTime).format("MM/DD/YYYY"),
          laboratoryReceivedDateTime: item?.laboratoryReceivedDateTime && dayjs(item?.laboratoryReceivedDateTime).format("MM/DD/YYYY"),
          collectionMethod: item?.collectionMethod,
          status: item?.status,
          sampleLocation: item?.sampleLocation ? item?.sampleLocation : "unset",
          action: permission === "administrator" && (
            <div className="d-flex gap-2">
              <Link to={`/sample-view/${item.id}`} className="btn btn-outline-primary">
                View
              </Link>
            </div>
          ),
        }))) ||
      [],
    [samplesData],
  )
  const filters = useMemo(() => {
    return [
      {
        field: "_id",
        operator: "$eq",
        label: "Search Sample ID",
        placeholder: "Type in Sample ID",
        removeSpaces: true,
      },
      {
        field: "subjectId",
        operator: "$eq",
        label: "Search Patient ID",
        placeholder: "Type in Patient ID",
        removeSpaces: true,
      },
      // {
      //   field: "laboratoryReceivedDateTime",
      //   operator: "$gte",
      //   label: "Accession Date",
      //   type: "date",
      // },
    ]
  }, [])

  return (
    <div>
      <TableContainer
        refetch={refetch}
        columns={columns}
        data={data}
        downloadFileName="Samples"
        isLoading={isLoading || isFetching}
        filters={filters}
        selector={selector}
        sort={sort}
        defaultSort="sampleCollectionDateTime"
        defaultSortDir="desc"
        {...rest}
      />
    </div>
  )
}
