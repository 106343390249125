import React, { useMemo } from "react"
import { useCovidTestsQuery } from "api/getters"
import dayjs from "dayjs"
import { Link } from "react-router-dom"
import utc from "dayjs/plugin/utc"
import TableContainer from "./TableContainer"
import { useTableQueryStore } from "stores/zustand/tableQueryStore"
dayjs.extend(utc)

export function CovidTestTable({ pageSize: pageSizeProp, customSelect = {}, ...rest }) {
  const { page, pageSize, selector, sort } = useTableQueryStore()
  const {
    data: testsData,
    isLoading,
    isFetching,
    error,
    refetch,
  } = useCovidTestsQuery({
    variables: {
      sort,
      page,
      selector: {
        ...selector,
        ...customSelect,
      },
      pageSize: pageSizeProp || pageSize,
    },
  })

  const columns = useMemo(
    () => [
      {
        Header: "Test ID",
        accessor: "_id",
        hasSort: true,
        csvAccessor: "csvId",
      },
      {
        Header: "Patient ID",
        accessor: "subjectId",
        hasSort: true,
        csvAccessor: "csvSubjectId",
      },
      {
        Header: "Collected",
        accessor: "sampleCollectionDateTime",
        hasSort: true,
      },
      {
        Header: "Accessioned",
        accessor: "laboratoryReceivedDateTime",
        hasSort: true,
      },
      {
        Header: "Analysed",
        accessor: "laboratoryCompletedDateTime",
        hasSort: true,
      },
      {
        Header: "Reported",
        accessor: "reportingDateTime",
        hasSort: true,
      },
      {
        Header: "Insured",
        accessor: "patientInsured",
        hasSort: true,
      },
      {
        Header: "Result",
        accessor: "testResult",
        hasSort: true,
      },
      {
        Header: "Action",
        accessor: "action",
        skipCsv: true,
      },
    ],
    [],
  )

  const data = useMemo(
    () =>
      (testsData &&
        testsData?.map((item) => ({
          // _id: (
          //   <Link to={`/cancer-tests-view/${item.id || ""}`} className="text-secondary">
          //     {item.id}
          //   </Link>
          // ),
          _id: item.id,
          subjectId: (
            <a href={`/subject-info/${item.subjectId}/personal`} className="text-secondary" target="_blank" rel="noopener noreferrer">
              {item.subjectId}
            </a>
          ),
          csvSubjectId: item.subjectId,
          csvId: item.id,
          testType: item?.testTypeName || item?.testType,
          tubeType: item?.tubeType,
          sampleCollectionDateTime: item?.sampleCollectionDateTime ? dayjs(item?.sampleCollectionDateTime).format("MM/DD/YYYY") : "",
          laboratoryReceivedDateTime: item?.laboratoryReceivedDateTime ? dayjs(item?.laboratoryReceivedDateTime).format("MM/DD/YYYY") : "",
          laboratoryCompletedDateTime: item?.laboratoryCompletedDateTime ? dayjs(item?.laboratoryCompletedDateTime).local().format("MM/DD/YYYY") : "",
          reportingDateTime: item?.laboratoryCompletedDateTime ? dayjs(item?.laboratoryCompletedDateTime).local().format("MM/DD/YYYY ") : "",
          patientInsured:
            item?.patientInsured === true || item?.insured === true ? "Yes" : item?.patientInsured === false || item?.insured === false ? "No" : "No",
          testResult: typeof item?.testResult === "object" ? item?.testResult?.scoreCategory : item?.testResult || "",
          action: (
            <Link to={`/test-view/${item?.id || ""}`} className="btn btn-outline-primary">
              View
            </Link>
          ),
        }))) ||
      [],
    [testsData],
  )

  const filters = useMemo(() => {
    return [
      {
        field: "_id",
        operator: "$eq",
        label: "Search ID",
        placeholder: "Type in ID",
        removeSpaces: true,
      },
      {
        field: "subjectId",
        operator: "$eq",
        label: "Patient ID",
        placeholder: "Type in Patient ID",
        removeSpaces: true,
      },
    ]
  }, [])

  return (
    <div>
      <TableContainer
        refetch={refetch}
        columns={columns}
        data={data}
        isLoading={isLoading || isFetching}
        filters={filters}
        selector={selector}
        sort={sort}
        defaultSort="sampleCollectionDateTime"
        defaultSortDir="desc"
        downloadFileName="Covid Tests"
        {...rest}
      />
    </div>
  )
}
