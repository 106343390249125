const insuranceCompanyFormDefinition = {
    formLabel: '',
    sections: [
        {
            label: '',
            columns: 1,
            fields: [
                {
                    label: 'Company Code',
                    name: 'id',
                    type: 'text',
                    required: true,
                    format: 'allCaps',
                    style: {maxWidth:'400px'}
                },
                {
                    label: 'Company Name',
                    name: 'name',
                    type: 'text',
                    required: true,
                    style: {maxWidth:'400px'}
                },
                {
                    label: 'Notes',
                    name: 'notes',
                    type: 'textarea',
                    style: { minWidth:'800px', minHeight: "250px"},
                    maxlength: 750
                },
                {
                    label: 'Supported Products',
                    name: 'productsData',
                    type: 'multiple-select',
                    style: { maxWidth:'800px', minHeight: "250px"},
                    required: true,
                    source: {
                        kind: 'lookup',
                        data: 'product'
                    },
                }
            ]
        },
    ],
    submit: {
        label: 'Submit',
        style: {
            cursor: 'pointer',
            padding: '14px 24px',
            borderRadius: '200px',
            backgroundColor: '#f64d52',
            boxShadow: 'inset 0 10px 3px 100px transparent',
            webkitTransition: 'box-shadow 200ms ease',
            transition: 'box-shadow 200ms ease',
            color: '#ffffff',
            fontWeight: 600,
            textAlign: 'center'
        },
        // disabled: {testId: {empty: true}}
    },
    update: {
        label: 'Update',
        style: {
            cursor: 'pointer',
            padding: '14px 24px',
            borderRadius: '200px',
            backgroundColor: '#f64d52',
            boxShadow: 'inset 0 10px 3px 100px transparent',
            webkitTransition: 'box-shadow 200ms ease',
            transition: 'box-shadow 200ms ease',
            color: '#ffffff',
            fontWeight: 600,
            textAlign: 'center',
            marginRight: '10px'
        },
        disabled: false
    },
    cancel: {
        label: 'Close',
        style: {
            cursor: 'pointer',
            padding: '14px 24px',
            borderRadius: '200px',
            backgroundColor: '#29335c',
            boxShadow: 'inset 0 10px 3px 100px transparent',
            webkitTransition: 'box-shadow 200ms ease',
            transition: 'box-shadow 200ms ease',
            color: '#ffffff',
            fontWeight: 600,
            textAlign: 'center'
        }
    },
    close: {
        label: 'Close',
        style: {
            cursor: 'pointer',
            padding: '14px 24px',
            borderRadius: '200px',
            backgroundColor: '#29335c',
            boxShadow: 'inset 0 10px 3px 100px transparent',
            webkitTransition: 'box-shadow 200ms ease',
            transition: 'box-shadow 200ms ease',
            color: '#ffffff',
            fontWeight: 600,
            textAlign: 'center'
        }
    },
    edit: {
        label: 'Edit',
        style: {
            cursor: 'pointer',
            padding: '14px 24px',
            borderRadius: '200px',
            backgroundColor: '#29335c',
            boxShadow: 'inset 0 10px 3px 100px transparent',
            webkitTransition: 'box-shadow 200ms ease',
            transition: 'box-shadow 200ms ease',
            color: '#ffffff',
            fontWeight: 600,
            textAlign: 'center'
        }
    },
};

export default insuranceCompanyFormDefinition;
