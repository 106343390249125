import React, { useState, useEffect, useMemo, useCallback } from "react"
import { Container, Row, Col, Card, Alert, CardBody, Button, Label, Input, FormFeedback, CardTitle, Form, Spinner } from "reactstrap"
import getUnicodeFlagIcon from "country-flag-icons/unicode"
import * as Yup from "yup"
import { Controller, useForm } from "react-hook-form"
// import Breadcrumb from "components/Common/Breadcrumb"
import { GENDER, RACE, ETHNICITY, COUNTRIES, US_STATES } from "constants"
// import avatar from "assets/images/users/avatar-1.jpg"
// import { useCurrentUserStore } from "store/zustand"
import { useUpdateSubject } from "api/mutators"
import { yupResolver } from "@hookform/resolvers/yup"
import dayjs from "dayjs"
// import UpdatePassword from "components/custom/UpdatePassword"
import utc from "dayjs/plugin/utc"
import { usePatientDetailsQuery } from "api/getters"
dayjs.extend(utc)

export const PatientDetails = ({ id, permission }) => {
  const { refetch, data: currentUser, isLoading: isLoadingDetails, remove } = usePatientDetailsQuery({ variables: { id } })

  const [editing, setEditing] = useState(false)
  const [successMessage, setSuccessMessage] = useState("")
  const [isUsa, setIsUsa] = useState(false)

  const schema = useMemo(() => {
    return Yup.object({
      email: Yup.string().email("Must be a valid email").required("Please enter email"),
      firstName: Yup.string().required("Please enter first name"),
      lastName: Yup.string().required("Please enter last name"),
      "1_phoneNumber": Yup.string().required("Please enter phone"),
      dateOfBirth: Yup.date().max(new Date(), "The date must be in the past.").required("Please enter date of birth"),
      gender: Yup.string().required("Please select gender"),
      race: Yup.string().required("Please select race"),
      ethnicity: Yup.string().required("Please select ethnicity"),
      "1_country": Yup.string().required("Please select country"),
      "1_city": Yup.string().required("Please enter city"),
      "1_region": isUsa ? Yup.string().nullable() : Yup.string().required("Please enter region"),
      "1_postalCode": isUsa ? Yup.string().nullable() : Yup.string().required("Please enter postal code"),
      "1_state": isUsa ? Yup.string().required("Please select state") : Yup.string().nullable(),
      "1_zipCode": isUsa ? Yup.string().required("Please enter zip code") : Yup.string().nullable(),
      "1_address": Yup.string().required("Please enter address"),
    })
  }, [isUsa])

  const {
    reset,
    watch,
    control,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      email: "",
      firstName: "",
      lastName: "",
      "1_phoneNumber": "",
      gender: "",
      dateOfBirth: undefined,
      race: "",
      ethnicity: "",
      "1_country": "",
      "1_city": "",
      "1_region": "",
      "1_postalCode": "",
      "1_state": "",
      "1_zipCode": "",
      "1_address": "",
      insured: false,
      taps: false,
    },
  })

  const watchCountry = watch("1_country")

  useEffect(() => {
    setIsUsa(watchCountry === "US")
  }, [watchCountry])

  const {
    mutate,
    isLoading,
    isError,
    error: updateError,
  } = useUpdateSubject({
    onSuccess: () => {
      refetch()
      setSuccessMessage("Successfully Updated")
      setEditing(false)
    },
  })

  const handleReset = useCallback(
    (user) => {
      reset({
        email: user.email,
        firstName: user.firstName,
        lastName: user.lastName,
        gender: user.gender || "",
        "1_phoneNumber": user.phoneNumber,
        dateOfBirth: user.dateOfBirth || undefined,
        "1_country": user.country || "",
        "1_city": user.city,
        "1_region": user.region,
        "1_postalCode": user.postalCode,
        "1_state": user.state || "",
        "1_zipCode": user.zipCode || "",
        "1_address": user.address,
        race: user.race || "",
        ethnicity: user.ethnicity || "",
        taps: user.taps || false,
        insured: user.insured || false,
      })
    },
    [reset],
  )

  useEffect(() => {
    if (currentUser?.id) {
      handleReset(currentUser)
    }
  }, [currentUser, handleReset])

  const onSubmit = handleSubmit(async (data) => {
    setSuccessMessage("")

    const dob = dayjs(data.dateOfBirth).format("YYYY-MM-DD")

    const formValues = {
      id,
      firstName: data.firstName || null,
      lastName: data.lastName || null,
      dateOfBirth: dob || null,
      gender: data.gender || null,
      phoneNumber: data["1_phoneNumber"] || null,
      country: data["1_country"] || null,
      city: data["1_city"] || null,
      region: isUsa ? null : data["1_region"],
      postalCode: isUsa ? null : data["1_postalCode"],
      state: isUsa ? data["1_state"] : null,
      zipCode: isUsa ? data["1_zipCode"] : null,
      address: data["1_address"] || null,
      race: data.race || null,
      ethnicity: data.ethnicity || null,
      insured: data.insured || null,
      taps: data.taps || null,
    }

    try {
      await mutate(formValues)
      currentUser.setCurrentUserDetails({
        ...formValues,
      })
    } catch (err) {
      console.error({ err })
    }
  })

  return (
    <div className="page-content">
      <Container fluid>
        {/* Render Breadcrumb */}
        {/* <Breadcrumb title="Personal" breadcrumbItem="" /> */}
        <Row>
          <Col>
            {successMessage ? <Alert color="success">{successMessage}</Alert> : null}
            {isError && (
              <Alert color="warning" role="alert">
                {updateError?.response?.data?.reason || updateError?.response?.data?.message || updateError?.message}
              </Alert>
            )}
            <Form onSubmit={onSubmit}>
              <Row>
                <Col xl={4}>
                  <div className="mb-3">
                    <Label className="form-label">ID</Label>
                    <Input className="form-control" value={id} readOnly />
                  </div>
                </Col>
                <Col xl={4}>
                  <div className="mb-3">
                    <Label className="form-label">Email</Label>
                    <Controller name="email" control={control} render={({ field }) => <Input readOnly className="form-control" {...field} />} />
                  </div>
                </Col>
              </Row>
              <Row>
                <Col xl={4}>
                  <div className="mb-3">
                    <Label className="form-label">First name</Label>
                    <Controller
                      name="firstName"
                      control={control}
                      render={({ field }) => (
                        <Input
                          disabled={!editing}
                          className="form-control"
                          placeholder="Enter first name"
                          invalid={Boolean(errors?.firstName?.message)}
                          {...field}
                        />
                      )}
                    />
                    {Boolean(errors?.firstName?.message) && <FormFeedback type="invalid">{errors.firstName.message}</FormFeedback>}
                  </div>
                </Col>
                <Col xl={4}>
                  <div className="mb-3">
                    <Label className="form-label">Last name</Label>
                    <Controller
                      name="lastName"
                      control={control}
                      render={({ field }) => (
                        <Input
                          disabled={!editing}
                          className="form-control"
                          placeholder="Enter last name"
                          invalid={Boolean(errors?.lastName?.message)}
                          {...field}
                        />
                      )}
                    />
                    {Boolean(errors?.lastName?.message) && <FormFeedback type="invalid">{errors.lastName.message}</FormFeedback>}
                  </div>
                </Col>
              </Row>

              <Row>
                <Col xl={4}>
                  <div className="mb-3">
                    <Label className="form-label">Phone number</Label>
                    <Controller
                      name="1_phoneNumber"
                      control={control}
                      render={({ field }) => (
                        <Input
                          type="tel"
                          autoComplete="off"
                          disabled={!editing}
                          className="form-control"
                          placeholder="Enter phone number"
                          invalid={Boolean(errors?.["1_phoneNumber"]?.message)}
                          {...field}
                          onInput={(e) => {
                            e.target.value = e.target.value.replace(/\D/g, "")
                          }}
                        />
                      )}
                    />
                    {Boolean(errors?.["1_phoneNumber"]?.message) && <FormFeedback type="invalid">{errors?.["1_phoneNumber"].message}</FormFeedback>}
                  </div>
                </Col>
                <Col xl={4}>
                  <div className="mb-3">
                    <Label className="form-label">Gender</Label>
                    <Controller
                      name="gender"
                      control={control}
                      render={({ field }) => (
                        <select disabled={!editing} className={`form-control ${Boolean(errors?.gender?.message) ? "is-invalid" : ""}`} {...field}>
                          <option key="default" value="" disabled>
                            Select gender
                          </option>
                          {GENDER.map((item) => (
                            <option key={item._id} value={item.key}>
                              {item.value}
                            </option>
                          ))}
                        </select>
                      )}
                    />
                    {Boolean(errors?.gender?.message) && <FormFeedback type="invalid">{errors.gender.message}</FormFeedback>}
                  </div>
                </Col>

                <Col xl={4}>
                  <div className="mb-3">
                    <Label className="form-label">Date of birth</Label>
                    <Controller
                      name="dateOfBirth"
                      control={control}
                      render={({ field }) => (
                        <input
                          disabled={!editing}
                          className={`form-control ${Boolean(errors?.dateOfBirth?.message) ? "is-invalid" : ""}`}
                          type="date"
                          {...field}
                        />
                      )}
                    />
                    {Boolean(errors?.dateOfBirth?.message) && <FormFeedback type="invalid">{errors.dateOfBirth.message}</FormFeedback>}
                  </div>
                </Col>
              </Row>

              <Row>
                <Col xl={6}>
                  <div className="mb-3">
                    <Label className="form-label">Race</Label>
                    <Controller
                      name="race"
                      control={control}
                      render={({ field }) => (
                        <select disabled={!editing} className={`form-control ${Boolean(errors?.race?.message) ? "is-invalid" : ""}`} {...field}>
                          <option key="default" value="" disabled>
                            Select race
                          </option>
                          {RACE.map((item) => (
                            <option key={item._id} value={item.key}>
                              {item.value}
                            </option>
                          ))}
                        </select>
                      )}
                    />
                    {Boolean(errors?.race?.message) && <FormFeedback type="invalid">{errors.race.message}</FormFeedback>}
                  </div>
                </Col>
                <Col xl={6}>
                  <div className="mb-3">
                    <Label className="form-label">Ethnicity</Label>
                    <Controller
                      name="ethnicity"
                      control={control}
                      render={({ field }) => (
                        <select disabled={!editing} className={`form-control ${Boolean(errors?.ethnicity?.message) ? "is-invalid" : ""}`} {...field}>
                          <option key="default" value="" disabled>
                            Select ethnicity
                          </option>
                          {ETHNICITY.map((item) => (
                            <option key={item._id} value={item.key}>
                              {item.value}
                            </option>
                          ))}
                        </select>
                      )}
                    />
                    {Boolean(errors?.ethnicity?.message) && <FormFeedback type="invalid">{errors.ethnicity.message}</FormFeedback>}
                  </div>
                </Col>
              </Row>

              <Row>
                <Col xl="8" className="mb-3">
                  <Label className="form-label">Country</Label>
                  <Controller
                    name="1_country"
                    control={control}
                    render={({ field }) => (
                      <select
                        autoComplete="off"
                        disabled={!editing}
                        className={`form-control ${Boolean(errors?.["1_country"]?.message) ? "is-invalid" : ""}`}
                        {...field}
                      >
                        <option key="default" value="" disabled>
                          Select country
                        </option>
                        {COUNTRIES.map((item) => (
                          <option key={item.iso2} value={item.iso2}>
                            {item.name} {getUnicodeFlagIcon(item.iso2)}
                          </option>
                        ))}
                      </select>
                    )}
                  />
                  {Boolean(errors?.["1_country"]?.message) && <FormFeedback type="invalid">{errors?.["1_country"].message}</FormFeedback>}
                </Col>
              </Row>

              <Row>
                <Col xl={4}>
                  <div className="mb-3">
                    <Label className="form-label">City</Label>
                    <Controller
                      name="1_city"
                      control={control}
                      render={({ field }) => (
                        <Input
                          autoComplete="off"
                          disabled={!editing}
                          className="form-control"
                          placeholder="Enter city"
                          invalid={Boolean(errors?.["1_city"]?.message)}
                          {...field}
                        />
                      )}
                    />
                    {Boolean(errors?.["1_city"]?.message) && <FormFeedback type="invalid">{errors["1_city"].message}</FormFeedback>}
                  </div>
                </Col>
                <Col xl={4}>
                  {isUsa ? (
                    <div className="mb-3">
                      <Label className="form-label">State</Label>
                      <Controller
                        key="state"
                        name="1_state"
                        control={control}
                        render={({ field }) => (
                          <select
                            autoComplete="off"
                            disabled={!editing}
                            className={`form-control ${Boolean(errors?.["1_state"]?.message) ? "is-invalid" : ""}`}
                            {...field}
                          >
                            <option key="default" value="" disabled>
                              Select state
                            </option>
                            {US_STATES.map((state) => (
                              <option key={state.key} value={state.key}>
                                {state.value}
                              </option>
                            ))}
                          </select>
                        )}
                      />
                      {Boolean(errors?.["1_state"]?.message) && <FormFeedback type="invalid">{errors["1_state"].message}</FormFeedback>}
                    </div>
                  ) : (
                    <div className="mb-3">
                      <Label className="form-label">Region</Label>
                      <Controller
                        key="region"
                        name="1_region"
                        control={control}
                        render={({ field }) => (
                          <Input
                            autoComplete="off"
                            disabled={!editing}
                            className="form-control"
                            placeholder="Enter region"
                            invalid={Boolean(errors?.["1_region"]?.message)}
                            {...field}
                          />
                        )}
                      />
                      {Boolean(errors?.["1_region"]?.message) && <FormFeedback type="invalid">{errors["1_region"].message}</FormFeedback>}
                    </div>
                  )}
                </Col>
                <Col xl={4} key={`${isUsa}`}>
                  {isUsa ? (
                    <div className="mb-3">
                      <Label className="form-label">Zip Code</Label>
                      <Controller
                        name="1_zipCode"
                        control={control}
                        render={({ field }) => (
                          <Input
                            autoComplete="off"
                            disabled={!editing}
                            className="form-control"
                            placeholder="Enter zip code"
                            invalid={Boolean(errors?.["1_zipCode"]?.message)}
                            {...field}
                          />
                        )}
                      />
                      {Boolean(errors?.["1_zipCode"]?.message) && <FormFeedback type="invalid">{errors["1_zipCode"].message}</FormFeedback>}
                    </div>
                  ) : (
                    <div className="mb-3">
                      <Label className="form-label">Postal Code</Label>
                      <Controller
                        name="1_postalCode"
                        control={control}
                        render={({ field }) => (
                          <Input
                            autoComplete="off"
                            disabled={!editing}
                            className="form-control"
                            placeholder="Enter postal code"
                            invalid={Boolean(errors?.["1_postalCode"]?.message)}
                            {...field}
                          />
                        )}
                      />
                      {Boolean(errors?.["1_postalCode"]?.message) && <FormFeedback type="invalid">{errors["1_postalCode"].message}</FormFeedback>}
                    </div>
                  )}
                </Col>
              </Row>

              <Row>
                <Col xl="8">
                  <Label className="form-label">Address</Label>
                  <Controller
                    name="1_address"
                    control={control}
                    render={({ field }) => (
                      <Input
                        autoComplete="off"
                        disabled={!editing}
                        className="form-control"
                        placeholder="Enter address"
                        invalid={Boolean(errors?.["1_address"]?.message)}
                        {...field}
                      />
                    )}
                  />
                  {Boolean(errors?.["1_address"]?.message) && <FormFeedback type="invalid">{errors?.["1_address"].message}</FormFeedback>}
                </Col>
                <Col xl="4">
                  <div>
                    <Controller
                      name="insured"
                      control={control}
                      render={({ field }) => (
                        <div className="form-check mt-2">
                          <input
                            {...field}
                            type="checkbox"
                            className={`form-check-input ${Boolean(errors?.insured?.message) ? "is-invalid" : ""}`}
                            id="formrow-customCheck1"
                            checked={field.value}
                            disabled={!editing}
                          />
                          <label className="form-check-label" htmlFor="formrow-customCheck1">
                            Insured
                          </label>
                        </div>
                      )}
                    />

                    <Controller
                      name="taps"
                      control={control}
                      render={({ field }) => (
                        <div className="form-check mt-2">
                          <input
                            {...field}
                            type="checkbox"
                            className={`form-check-input ${Boolean(errors?.taps?.message) ? "is-invalid" : ""}`}
                            id="formrow-customCheck2"
                            checked={field.value}
                            disabled={!editing}
                          />
                          <label className="form-check-label" htmlFor="formrow-customCheck2">
                            Taps
                          </label>
                          {Boolean(errors?.taps?.message) && <FormFeedback type="invalid">{errors?.taps.message}</FormFeedback>}
                        </div>
                      )}
                    />
                  </div>
                </Col>
              </Row>
              {permission === "administrator" && (
                <div className="d-flex gap-2 mt-4">
                  {editing && (
                    <Button
                      key="cancel"
                      outline
                      type="button"
                      disabled={isLoading}
                      onClick={() => {
                        setEditing(false)
                        handleReset(currentUser)
                      }}
                    >
                      Cancel
                    </Button>
                  )}
                  {editing ? (
                    <Button key="submit" color="danger" type="submit" disabled={isLoading}>
                      {isLoading && <Spinner size="sm" />} Update Details
                    </Button>
                  ) : (
                    <Button key="edit" color="danger" type="button" onClick={() => setEditing(true)}>
                      Edit Details
                    </Button>
                  )}
                </div>
              )}
            </Form>
          </Col>
        </Row>
      </Container>
    </div>
  )
}

export default PatientDetails
