import React, { useMemo, useState, useEffect } from "react"
import { usePrintQuery } from "api/getters"
import dayjs from "dayjs"
import utc from "dayjs/plugin/utc"
import { Link } from "react-router-dom"
import { useTableQueryStore } from "stores/zustand/tableQueryStore"
import TableContainer from "./TableContainer"
import Select from "react-select"
import { useAlert } from "react-alert"
import axios from "axios"

dayjs.extend(utc)

export function PrintQueuesTable({ pageSize: pageSizeProp, ...rest }) {
  const alert = useAlert()
  const [isDownloading, setIsDownloading] = useState(false)
  const [downloadId, setDownloadId] = useState(null)
  const [tableData, setTableData] = useState([])

  const { page, pageSize, selector, sort } = useTableQueryStore()

  const {
    data: printsData,
    isLoading,
    isFetching,
    refetch,
  } = usePrintQuery({
    variables: {
      sort,
      page,
      selector,
      pageSize: pageSizeProp || pageSize,
    },
  })

  useEffect(() => {
    if (printsData) {
      setTableData(printsData)
    }
  }, [printsData])

  const columns = useMemo(
    () => [
      {
        Header: "Print Id",
        accessor: "id",
        type: "text",
        hasSort: true,
      },
      {
        Header: "Patient Id",
        accessor: "data.data.subjectId",
        csvAccessor: "data.data.subjectId",
        type: "text",
        hasSort: true,
        Cell: ({ value }) => (
          <Link to={`/subject-info/${value}/personal`} className="text-secondary" target="_blank">
            {value}
          </Link>
        ),
      },
      {
        Header: "Test Id",
        accessor: "data.data.testId",
        csvAccessor: "data.data.testId",
        type: "text",
        hasSort: true,
      },
      {
        Header: "Document Name",
        accessor: "filename",
        type: "text",
        hasSort: true,
      },
      {
        Header: "Queue Date",
        accessor: "queuedDateTime",
        hasSort: true,
        Cell: ({ value }) => (value ? dayjs(value).local().format("MM/DD/YYYY") : ""),
      },
      {
        Header: "Status",
        accessor: "status",
        type: "text",
        hasSort: true,
        Cell: ({ row }) => {
          const { status, id } = row.original
          return (
            <div className="d-flex gap-2 align-items-center">
              <span>{status}</span>
              {status === "queued" && (
                <div className="d-flex gap-2">
                  <button className="btn btn-outline-primary" onClick={() => downloadReport(row.original)}>
                    Print
                  </button>
                  <button className="btn btn-outline-success" onClick={() => handleDone(id)}>
                    Done
                  </button>
                </div>
              )}
            </div>
          )
        },
      },
    ],
    [downloadId, isDownloading],
  )

  const savePrint = async (record) => {
    if (record) {
      const { _rev, ...recordData } = record
      const uri = "/api/netest/print"

      try {
        const response = await axios.post(uri, recordData)
        if (response.status === 200) {
          alert.show("Print document saved", {
            type: "success",
          })
        }
      } catch (error) {
        alert.show(`Error saving print document: ${error.message}`, {
          type: "error",
        })
      }
    }
  }

  const downloadReport = async (record) => {
    console.log("Downloading report for", record)
    setDownloadId(record.id)
    setIsDownloading(true)
    try {
      await printDocument(record)
    } finally {
      setIsDownloading(false)
      setDownloadId(null)
    }
  }

  const printDocument = async (record) => {
    console.log("print document", record)
    const fileName = record.filename
    setIsDownloading(true)
    try {
      const uri = `/api/document/print/${fileName}`
      if (record) {
        await savePrint(record)
        const response = await axios.get(uri, {
          responseType: "blob",
        })

        const blob = new Blob([response.data], { type: "application/pdf" })
        const downloadUrl = URL.createObjectURL(blob)
        const link = document.createElement("a")
        link.href = downloadUrl
        link.download = fileName
        document.body.appendChild(link)
        link.click()
        link.remove()
        URL.revokeObjectURL(downloadUrl)

        alert.show("File is ready for printing", {
          type: "success",
        })
      } else {
        alert.show("Unable to fetch record", {
          type: "error",
        })
      }
    } catch (err) {
      alert.show(`Error in download action: ${err.message}`, {
        type: "error",
      })
    } finally {
      setIsDownloading(false)
      refetch()
    }
  }

  const handleDone = async (itemId) => {
    setIsDownloading(true)
    try {
      const response = await axios.patch(`/api/print/${itemId}`, {
        status: "done",
      })
      if (response.status === 201) {
        alert.show("Status updated to done.", {
          type: "success",
        })

        setTableData((prevData) => prevData.map((item) => (item.id === itemId ? { ...item, status: "done" } : item)))
        window.location.reload()
      } else {
        alert.show("Failed to update status.", {
          type: "error",
        })
      }
    } catch (error) {
      alert.show(`Please print the document before marking it as 'done'.`, { type: "error" })
    } finally {
      setIsDownloading(false)
    }
  }

  const data = useMemo(
    () =>
      tableData.map((item) => ({
        id: item.id,
        data: {
          data: {
            subjectId: item.data.data.subjectId,
            testId: item.data.data.testId,
          },
        },
        filename: item.filename,
        queuedDateTime: item?.queuedDateTime,
        status: item.status,
      })) || [],
    [tableData],
  )

  const filters = useMemo(
    () => [
      {
        field: "_id",
        operator: "$eq",
        label: "Search Print Id",
        placeholder: "Type in Print Id",
        removeSpaces: true,
      },
      {
        field: "status",
        operator: "$eq",
        label: "Status",
        removeSpaces: true,
        placeholder: "Select Status",
        renderCustom: (filter, value, onChange) => {
          const options = [
            { label: "Queued", value: "queued" },
            { label: "Done", value: "done" },
          ]
          return (
            <Select
              isClearable
              classNamePrefix="select2-selection"
              placeholder={filter.placeholder}
              value={options.find((option) => option.value === value) || null}
              options={options}
              onChange={(selected) => onChange(selected?.value)}
            />
          )
        },
      },
    ],
    [],
  )

  return (
    <div>
      <TableContainer
        refetch={refetch}
        columns={columns}
        data={data}
        isLoading={isLoading || isFetching}
        filters={filters}
        selector={selector}
        sort={sort}
        page={page}
        pageSize={pageSize}
        defaultSort="queuedDateTime"
        defaultSortDir="desc"
        downloadFileName="Print Queues"
        {...rest}
      />
    </div>
  )
}
