import React, { useCallback, useEffect, useMemo, useState } from "react"
import { useAccessionsQuery } from "api/getters"
import dayjs from "dayjs"
import { Link } from "react-router-dom"
import utc from "dayjs/plugin/utc"
import TableContainer from "./TableContainer"
import { useTableQueryStore } from "stores/zustand/tableQueryStore"
import { Badge, Button, Modal, ModalBody, ModalFooter, ModalHeader, Spinner } from "reactstrap"
import useDisclosure from "hooks/disclosure"
import { ModalTitle } from "react-bootstrap"
import { useAlert } from "react-alert"
import { makeApiCall } from "api/generic-api"
dayjs.extend(utc)

export function AccessionBacklogTable({ pageSize: pageSizeProp, customSelect = {}, ...rest }) {
  const permission = rest?.applicationState && rest.applicationState.authentication.user.permission

  const alert = useAlert()
  const { page, pageSize, selector, sort } = useTableQueryStore()

  const { isOpen, toggleModal, closeModal } = useDisclosure()

  const {
    data: requisitionsData,
    isLoading,
    refetch,
  } = useAccessionsQuery({
    variables: {
      sort,
      page,
      selector: {
        ...selector,
        ...customSelect,
      },
      pageSize: pageSizeProp || pageSize,
    },
  })

  const [deleting, setDeleting] = useState(false)
  const [checkAll, setCheckAll] = useState(false)
  const [checkboxes, setCheckboxes] = useState([])
  const [deletedIds, setDeletedIds] = useState([])

  const columns = useMemo(
    () => [
      {
        Header: (
          <input
            type="checkbox"
            className={`form-check-input`}
            checked={checkAll}
            onChange={(e) => {
              e.persist()
              setCheckAll(e.target?.checked || false)
            }}
          />
        ),
        accessor: "checkbox",
        skipCsv: true,
      },
      {
        Header: "Submitted",
        accessor: "data.submittedTimestamp",
        csvAccessor: "submittedTimestamp",
        hasSort: true,
      },
      {
        Header: "Test ID",
        accessor: "data.testId",
        csvAccessor: "testId",
        hasSort: true,
      },
      {
        Header: "Collected",
        accessor: "data.sampleCollectionDateTime",
        csvAccessor: "sampleCollectionDateTime",
        hasSort: true,
      },
      {
        Header: "Requisitioner",
        accessor: "data.requisitionerFullName",
        csvAccessor: "requisitionerFullName",
        hasSort: true,
      },
      {
        Header: "First Name",
        accessor: "data.firstName",
        csvAccessor: "firstName",
        hasSort: true,
      },
      {
        Header: "Last Name",
        accessor: "data.lastName",
        csvAccessor: "lastName",
        hasSort: true,
      },
      {
        Header: "Date of Birth",
        accessor: "data.dateOfBirth",
        csvAccessor: "dateOfBirth",
        hasSort: true,
      },
      {
        Header: "Action",
        accessor: "action",
        skipCsv: true,
      },
    ],
    [checkAll],
  )

  const data = useMemo(
    () =>
      (requisitionsData &&
        requisitionsData
          ?.filter((item) => !deletedIds.includes(item.id))
          .map((item, idx) => ({
            checkbox: (
              <input
                key={idx}
                type="checkbox"
                className={`form-check-input`}
                checked={checkboxes?.[idx]?.checked}
                onChange={(e) => {
                  e.persist()
                  setCheckboxes((prev) => {
                    const newPrev = [...prev]
                    newPrev[idx].checked = e.target?.checked || false
                    return newPrev
                  })
                  setCheckAll(false)
                }}
              />
            ),
            testId: item?.data?.testId,
            requisitionerFullName: item?.data?.requisitionerFullName,
            sampleCollectionDateTime: item?.data.sampleCollectionDateTime ? dayjs(item?.data.sampleCollectionDateTime).format("MM/DD/YYYY") : "",
            submittedTimestamp: item?.data.submittedTimestamp ? dayjs(item?.data.submittedTimestamp).format("MM/DD/YYYY") : "",
            firstName: item?.data.firstName,
            lastName: item?.data.lastName,
            dateOfBirth: item?.data.dateOfBirth && dayjs(item?.data.dateOfBirth).format("MM/DD/YYYY"),
            data: {
              testId: item?.data?.testId,
              requisitionerFullName: item?.data?.requisitionerFullName,
              sampleCollectionDateTime: item?.data.sampleCollectionDateTime ? dayjs(item?.data.sampleCollectionDateTime).format("MM/DD/YYYY") : "",
              submittedTimestamp: item?.data.submittedTimestamp ? dayjs(item?.data.submittedTimestamp).format("MM/DD/YYYY") : "",
              firstName: item?.data.firstName,
              lastName: item?.data.lastName,
              dateOfBirth: item?.data.dateOfBirth && dayjs(item?.data.dateOfBirth).format("MM/DD/YYYY"),
            },
            csvStatus: item?.status || "pending",
            status: (
              <Badge className="text-uppercase" color={item?.status === "accepted" ? "success" : item?.status === "rejected" ? "danger" : "secondary"}>
                {item?.status || "pending"}
              </Badge>
            ),
            action: (
              <Link to={`/accession-view/${item?.id}`} className="btn btn-outline-primary">
                View
              </Link>
            ),
          }))) ||
      [],
    [deletedIds, requisitionsData, checkboxes],
  )

  useEffect(() => {
    setCheckboxes((prev) =>
      checkAll
        ? prev?.map((item) => ({ id: item.id, checked: true }))
        : prev.filter((item) => item.checked).length === prev?.length
        ? prev?.map((item) => ({ id: item.id, checked: false }))
        : prev,
    )
  }, [checkAll])

  useEffect(() => {
    if (requisitionsData?.length) {
      setCheckboxes(requisitionsData?.map((item) => ({ id: item.id, checked: false })))
      setCheckAll(false)
    }
  }, [requisitionsData])

  const filters = useMemo(() => {
    return [
      {
        field: "data.testId",
        operator: "$eq",
        label: "Test ID",
        placeholder: "Type in test ID",
      },
      {
        field: "data.requisitionerFullName",
        operator: "$eq",
        label: "Requisitioner",
        placeholder: "Type in requisitioner",
      },
      {
        field: "data.firstName",
        operator: "$eq",
        label: "First Name",
        placeholder: "Type in first name",
      },
      {
        field: "data.lastName",
        operator: "$eq",
        label: "Last Name",
        placeholder: "Type in last name",
      },
      {
        label: "Date of Birth",
        field: "data.dateOfBirth",
        operator: "$eq",
        type: "date",
      },
    ]
  }, [])

  const handleDelete = useCallback(async () => {
    setDeleting(true)
    const ids = checkboxes.filter((item) => item.checked).map((item) => item.id)
    const result = await makeApiCall("put", "/api/accession/streamline/bulk-delete", ids)
    if (result.ok) {
      setDeletedIds((prev) => [...prev, ...checkboxes.filter((item) => item.checked).map((item) => item.id)])
      setCheckboxes(checkboxes.filter((item) => !item.checked))
      refetch()
      alert.show("Record successfully deleted", {
        type: "success",
      })
      window.scrollTo(0, document.body.scrollHeight)
      closeModal()
    } else {
      const detail = `Details: code ${result.statusCode}; ${result.error}`
      alert.show(detail, {
        type: "error",
      })
      window.scrollTo(0, document.body.scrollHeight)
    }
    setDeleting(false)

    return result
  }, [checkboxes, refetch])

  return (
    <div>
      <TableContainer
        refetch={refetch}
        columns={columns}
        data={data}
        isLoading={isLoading}
        filters={filters}
        selector={selector}
        sort={sort}
        defaultSort="data.submittedTimestamp"
        defaultSortDir="desc"
        enableDelete={checkboxes?.filter((item) => item?.checked)?.length}
        handleDelete={toggleModal}
        showDelete={permission === "administrator"}
        downloadFileName="Accession Backlog"
        {...rest}
      />
      <Modal toggle={toggleModal} isOpen={isOpen}>
        <ModalHeader>
          <ModalTitle>Confirm Delete</ModalTitle>
        </ModalHeader>
        <ModalBody>Are you sure you want to delete these record(s)</ModalBody>
        <ModalFooter>
          <Button color="danger" disabled={deleting} onClick={handleDelete}>
            {deleting && <Spinner size="sm" />} Yes
          </Button>
          <Button outline disabled={deleting} onClick={closeModal}>
            No
          </Button>
        </ModalFooter>
      </Modal>
    </div>
  )
}
