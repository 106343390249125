import extrasFormDefinition from "./extras-form-definition";

const extrasFullDefinition = {
    key: '_id',
    table: {
        key: '_id',
        columns: [
            {
                label: '#',
                type: 'rowNumber',
                filterType: 'none',
                sortType: 'none'
            },
            {
                label: 'Key',
                field: 'id',
                type: 'text',
                filterType: 'equals'
            },
            {
                label: 'Name',
                field: 'extraName',
                type: 'text',
                filterType: 'equals'
            },
            {
                label: 'Value',
                field: 'extraValue',
                type: 'text',
                filterType: 'equals'
            }
        ],
    },

    form: extrasFormDefinition,
    endpoints : {
        search: {
            uri: '/api/extras?',
            verb: 'get'
        },
        delete: {
            uri: '/api/extra/:id',
            verb: 'delete'
        },

    },
    pageSize: 10
};

export default extrasFullDefinition;
