import React, { useEffect, useRef, useState } from 'react';
import {Row, Col} from 'reactstrap';
import FileUploader from './file-uploader';
import DataWow from "./data-wow";
import {makeApiCall} from "../api/generic-api";
import lookups from "../configuration/lookups";
import templateFullDefinition from "../configuration/template-full-definition";
import ReactLoading from "react-loading";
import DataWowTemplates from "./data-wow-templates";
import { useAlert } from "react-alert";

function TemplatesView(props) {
	const [ testConfiguration, setTestConfiguration ] = useState([]);
	const [ loading, setLoading ] = useState(false);
	
	const structure = templateFullDefinition;
	const childRef = useRef(null);
	const alert = useAlert()
	
	const fixedSelector = {
		$or: [
			{
				cancerPcr: true
			},
			{
				cancerPcr: {
					$exists: true
				}
			}
		]
	};
	
	const callRefresh = () => {
		childRef.current.callRefresh();
	};
	
	useEffect(() => {
		getTestConfiguration();
	}, []);
	
	const getTestConfiguration = async () => {
		setLoading(true)
		const result = await makeApiCall('get', `/api/test-configuration`);
		setTestConfiguration(result.body)
		setLoading(false);
	};
	
	
	const customRowActions = [
		{
			type: 'button',
			label: 'Download',
			color: 'warning',
			style: {color: 'black'},
			details: {
				download: true,
				endpoint: 'download',
				idToken: ':id',
				
			}
		}
	];
	
	const updateTemplate = async (formState) => {
		const result = await makeApiCall('put', `/api/document/template`, formState);
		if (result.ok) {
			alert.show("Successfully updated", {
				type: 'success'
			})
		} else {
			const details = `Details: code ${result.statusCode}; ${result.error}`;
			alert.show(details, {
				type: 'error'
			})
		}
		
		return result;
	};
	
	return (
		<div>
			<div className="pcr-results">
				<Row className="mt-5">
					<Col sm="6" className="offset-3">
						<div className="rounded bg-body-secondary px-3 px-sm-4 py-3 py-sm-4">
						<FileUploader
							title="Upload Templates"
							uri="/api/template-file"
							refresh={callRefresh}
							showForTesting={false}
							allowedExtensions={['pdf', 'txt', 'html', 'json']}
							filteredExtensions={'application/pdf, text/txt, text/html, json'}
							{...props}/>
						</div>
					</Col>
				</Row>
			</div>
			
			<h3 className="text-center mt-5">Template Configuration</h3>
			{ loading &&
				<center className="mt-5">
					<ReactLoading type='spin' color='red' height={667} width={100} />
				</center>
			}
			
			{!loading && testConfiguration.length &&
				<DataWowTemplates
					className={'wide-container'}
					structure={structure}
					fixedSelector={fixedSelector}
					loadData={makeApiCall}
					lookups={lookups}
					ref={childRef}
					customRowActions={customRowActions}
					// showViewFormAction={true}
					showPaginationButtons={false}
					update={updateTemplate}
					submit={updateTemplate}
					formSource = {
						[
							{
								name: 'testTypeData',
								data: testConfiguration
							}
						]
					}
					{...props} />
			}
			
		</div>
	);
}

export default TemplatesView;
