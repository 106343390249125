const pricesFormDefinition = {
    formLabel: '',
    sections: [
        {
            label: '',
            columns: 1,
            fields: [
                {
                    label: 'Product',
                    name: 'productId',
                    type: 'select',
                    required: true,
                    style: { maxWidth:'400px'},
                    source: {
                        kind: 'lookup',
                        data: 'product'
                    },
                    nonEditable: true,
                },
                {
                    label: 'Payer Type',
                    name: 'payerType',
                    type: 'select',
                    required: true,
                    style: { maxWidth:'400px'},
                    source: {
                        kind: 'lookup',
                        data: 'payerType'
                    },
                    nonEditable: true,
                },
                {
                    label: 'Fee Code',
                    name: 'feeCode',
                    type: 'text',
                    style: {maxWidth:'400px'},
                    nonEditable: true,
                    maxlength: 10,
                    pattern: '^[a-zA-Z0-9]+$',
                    title: 'It only accepts alphanumeric code',
                },
                {
                    label: 'Price',
                    name: 'price',
                    type: 'number',
                    required: true,
                    style: {maxWidth:'400px'},
                    pattern: "^[0-9]"
                },
            ]
        },
        {
            label: 'Price History',
            type: 'table',
            name: 'history',
            fields:[{name: 'history'}],
            table: {
                columns: [
                    {
                        label: 'Author',
                        field: 'author.author',
                        filterType: 'none',
                        sortType: 'none'
                    },
                    {
                        label: 'Price',
                        field: 'price',
                        filterType: 'none',
                        sortType: 'none'
                    },
                    {
                        label: 'Action',
                        field: 'action',
                        filterType: 'none',
                        sortType: 'none'
                    },
                    {
                        label: 'Updated At',
                        type: 'timestamp',
                        format: 'toLocalizedDateString',
                        field: 'updatedTimestamp',
                        filterType: 'none',
                        sortType: 'none'
                    },
                ]
            }
        }
    ],
    submit: {
        label: 'Submit',
        style: {
            cursor: 'pointer',
            padding: '14px 24px',
            borderRadius: '200px',
            backgroundColor: '#f64d52',
            boxShadow: 'inset 0 10px 3px 100px transparent',
            webkitTransition: 'box-shadow 200ms ease',
            transition: 'box-shadow 200ms ease',
            color: '#ffffff',
            fontWeight: 600,
            textAlign: 'center'
        },
        // disabled: {testId: {empty: true}}
    },
    update: {
        label: 'Update',
        style: {
            cursor: 'pointer',
            padding: '14px 24px',
            borderRadius: '200px',
            backgroundColor: '#f64d52',
            boxShadow: 'inset 0 10px 3px 100px transparent',
            webkitTransition: 'box-shadow 200ms ease',
            transition: 'box-shadow 200ms ease',
            color: '#ffffff',
            fontWeight: 600,
            textAlign: 'center',
            marginRight: '10px'
        },
        disabled: false
    },
    cancel: {
        label: 'Close',
        style: {
            cursor: 'pointer',
            padding: '14px 24px',
            borderRadius: '200px',
            backgroundColor: '#29335c',
            boxShadow: 'inset 0 10px 3px 100px transparent',
            webkitTransition: 'box-shadow 200ms ease',
            transition: 'box-shadow 200ms ease',
            color: '#ffffff',
            fontWeight: 600,
            textAlign: 'center'
        }
    },
    close: {
        label: 'Close',
        style: {
            cursor: 'pointer',
            padding: '14px 24px',
            borderRadius: '200px',
            backgroundColor: '#29335c',
            boxShadow: 'inset 0 10px 3px 100px transparent',
            webkitTransition: 'box-shadow 200ms ease',
            transition: 'box-shadow 200ms ease',
            color: '#ffffff',
            fontWeight: 600,
            textAlign: 'center'
        }
    },
    edit: {
        label: 'Edit',
        style: {
            cursor: 'pointer',
            padding: '14px 24px',
            borderRadius: '200px',
            backgroundColor: '#29335c',
            boxShadow: 'inset 0 10px 3px 100px transparent',
            webkitTransition: 'box-shadow 200ms ease',
            transition: 'box-shadow 200ms ease',
            color: '#ffffff',
            fontWeight: 600,
            textAlign: 'center'
        }
    },
};

export default pricesFormDefinition;
