import pricesFormDefinition from "./prices-form-definition";

const pricesFullDefinition = {
    key: '_id',
    table: {
        key: '_id',
        columns: [
            {
                label: '#',
                type: 'rowNumber',
                filterType: 'none',
                sortType: 'none'
            },
            {
                label: 'Product',
                field: 'productId',
                type: 'text',
                filterType: 'equals'
            },
            {
                label: 'Payer Type',
                field: 'payerType',
                type: 'text',
                filterType: 'equals'
            },
            {
                label: 'Fee Code',
                field: 'feeCode',
                type: 'text',
                filterType: 'equals',
                maxlength: 10,
                pattern: '^[a-zA-Z0-9]+$',
                title: 'It only accepts alphanumeric code',
            },
            {
                label: 'Price',
                field: 'price',
                type: 'text',
                filterType: 'equals'
            },
        ],
    },

    form: pricesFormDefinition,
    endpoints : {
        search: {
            uri: '/api/prices?',
            verb: 'get'
        },
        delete: {
            uri: '/api/price/:id',
            verb: 'delete'
        },

    },
    pageSize: 10
};

export default pricesFullDefinition;
