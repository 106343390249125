import React, { Component, useState } from 'react';
//import { Redirect } from 'react-router-dom';
import PropTypes from 'prop-types';
import fetch from 'isomorphic-fetch';

import {
    Form,
    FormGroup,
    Label,
    Input,
    Container,
    Row,
    Col,
    Button,
    Alert
} from 'reactstrap';

const Password = props => {
    const [ state, setState ] = useState({
        password1: '',
        password2: '',
        invalidPasswordMessage: null,
        validPasswordMessage: null
    });
    
    const handleSubmit = (event) => {
        event.preventDefault();
        const id = props.applicationState.authentication.user._id ? props.applicationState.authentication.user._id : props.applicationState.authentication.user.id;
        let url = '/api/user/' +  encodeURIComponent(id) + '/password';
        let tokenParam = window.location.search.substring(1);
        if(tokenParam) {
            url += '?' + tokenParam;
        }
        console.log(url);
        fetch(
          url,
          {
              body: JSON.stringify({password: state.password1}),
              cache: 'no-cache',
              credentials: 'same-origin',
              headers: {
                  'content-type': 'application/json'
              },
              method: 'PUT',
              mode: 'cors',
              redirect: 'follow',
              referrer: 'no-referrer'
          })
          .then(response => {
              if(response.ok) {
                  setState(prevState => ({
                      ...prevState,
                      password1: '',
                      password2: '',
                      invalidPasswordMessage: null,
                      validPasswordMessage: 'Done'
                  }));
                  
                  return true;
              }
              setState(prevState => ({
                  ...prevState,
                  password1: '',
                  password2: '',
                  invalidPasswordMessage: 'Error',
                  validPasswordMessage: null
              }));
              
              throw new Error('/api/user password set call failed, status: ' + response.status);
              
          })
          .catch((reason) => {
              props.setError('Could not save the changes');
          });
    }
    
    const handlePassword1Change = (e) => {
        e.persist();
        setState(prevState => ({...prevState, password1: e.target?.value}));
        setPasswordMessages(e.target?.value, state.password2);
    }
    
    const handlePassword2Change = (e) => {
        e.persist();
        setState(prevState => ({...prevState, password2: e.target?.value}));
        setPasswordMessages(state.password1, e.target?.value);
    }
    
    const setPasswordMessages = (password1, password2) => {
        let validPasswordMessage = null;
        let invalidPasswordMessage = null;
        if (!password1 && !password2) {
            invalidPasswordMessage = null;
        } else if (password1 && password1.length < 7) {
            invalidPasswordMessage = 'Password is too short';
        } else if (password1 !== password2) {
            invalidPasswordMessage = 'Passwords are different';
        } else {
            validPasswordMessage = 'Good to go!';
        }
        
        setState(prevState => ({
            ...prevState,
            validPasswordMessage: validPasswordMessage,
            invalidPasswordMessage: invalidPasswordMessage
        }));
    }
    
    return (
      <div>
          <Container style={{width:'500px', marginTop:'20px'}}>
          
              <Row>
                  <Col>
                      <h1>Change Password</h1>
                      <Form onSubmit={handleSubmit} >
                          <FormGroup>
                              <Label for="Password1">New Password</Label>
                              <Input
                                type="password"
                                name="password1"
                                id="Password1"
                                placeholder="your new password for this system"
                                value={state.password1}
                                onChange={handlePassword1Change}
                              />
                          </FormGroup>
                          <FormGroup>
                              <Label for="Password2">Repeat New Password</Label>
                              <Input
                                type="password"
                                name="password2"
                                id="Password2"
                                placeholder="repeat your new password"
                                value={state.password2}
                                onChange={handlePassword2Change}
                              />
                          </FormGroup>
                          {
                              state.invalidPasswordMessage ?
                                <Alert color="danger">{state.invalidPasswordMessage}</Alert> :
                                null
                          }
                          {
                              state.validPasswordMessage ?
                                <Alert color="success">{state.validPasswordMessage}</Alert> :
                                null
                          }
                          <Button type="submit" outline color="primary" className="float-right"
                                  disabled={state.invalidPasswordMessage !== null}>OK</Button>
                      </Form>
                  </Col>
              </Row>
      
          </Container>
  
      </div>
    );
};



export default Password;
