import React, { useState, useEffect } from "react";
import { Button, Label, Row, Col, Dropdown, DropdownToggle, Spinner, DropdownMenu, DropdownItem, Alert } from "reactstrap";
import dayjs from "dayjs";
import { Link } from "react-router-dom";
import localizedFormat from "dayjs/plugin/localizedFormat";
import utc from "dayjs/plugin/utc";
import { makeApiCall } from "api/generic-api";
import { useProductList, useProductDetailSubmit } from "api/getters";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import { FaTimes } from "react-icons/fa";

dayjs.extend(utc);
dayjs.extend(localizedFormat);

const schema = Yup.object().shape({
    productCode: Yup.string().required("Product Code is required"),
    productName: Yup.string().required("Product Name is required"),
    status: Yup.string().required("Status is required"),
});

const ProductsDetails = ({ productId }) => {
    const [isSubmitted, setIsSubmitted] = useState(false);
    const [isLoading, setIsLoading] = useState(true);
    const [testConfiguration, setTestConfiguration] = useState([]);
    const [extrasConfiguration, setExtrasConfiguration] = useState([]);
    const [extrasConfigurationDropdownOpen, setExtrasConfigurationDropdownOpen] = useState(false);
    const [selectedExtrasConfiguration, setSelectedExtrasConfiguration] = useState([]);
    const [statusDropdownOpen, setStatusDropdownOpen] = useState(false);
    const [selectedStatus, setSelectedStatus] = useState("");
    const [testTypeDropdownOpen, setTestTypeDropdownOpen] = useState(false);
    const [selectedTestTypes, setSelectedTestTypes] = useState([]);
    const [productGroupDropdownOpen, setProductGroupDropdownOpen] = useState(false);
    const [selectedProductGroup, setSelectedProductGroup] = useState("");
    const [editing, setEditing] = useState(false);
    const [successMessage, setSuccessMessage] = useState("");
    const [errorMessage, setErrorMessage] = useState("");

    const { data, isLoading: isLoadingProduct } = useProductList({
        variables: {
            page: 1,
            selector: {
                _id: productId,
            },
        },
    });

    const { control, handleSubmit, register, setValue, formState: { errors, isDirty } } = useForm({
        resolver: yupResolver(schema),
    });

    useEffect(() => {
        const fetchConfigurations = async () => {
            setIsLoading(true);
            try {
                const [testResult, extrasResult] = await Promise.all([
                    makeApiCall("get", "/api/test-configuration"),
                    makeApiCall("get", "/api/extras-products"),
                ]);
                setTestConfiguration(testResult.body);

                setExtrasConfiguration(extrasResult.body);
            } catch (error) {
                if (error.response?.data?.error) {
                    setErrorMessage(error.response.data.error);
                } else {
                    setErrorMessage("Failed to fetch configurations");
                }
            } finally {
                setIsLoading(false);
            }
        };

        fetchConfigurations();
    }, []);

    useEffect(() => {
        if (data?.[0]) {
            const product = data[0];
            setSelectedExtrasConfiguration(
                (product.extrasConfiguration ?? []).map(config => config.label)
            );
            setSelectedStatus(
                product.status
                    ? product.status.charAt(0).toUpperCase() + product.status.slice(1).toLowerCase()
                    : ""
            );
            setSelectedTestTypes(
                product.testTypeData.map(type => type.label.toLowerCase()) || []
            );
            setSelectedProductGroup(product.productGroup?.toLowerCase() || "");
        }
    }, [data]);

    const { mutate, isLoading: mutating, isError, error } = useProductDetailSubmit({
        onSuccess: () => {
            setSuccessMessage("Product has been successfully updated.");
            setErrorMessage("");
            setIsSubmitted(true);
        },
        onError: (error) => {
            const apiErrorMessage = error?.response?.data?.error;
            console.error("API Error:", apiErrorMessage);
            setErrorMessage(apiErrorMessage || "An error occurred");
        },
    });

    const dropdownStates = {
        extrasConfiguration: extrasConfigurationDropdownOpen,
        status: statusDropdownOpen,
        testType: testTypeDropdownOpen,
        productGroup: productGroupDropdownOpen
    };

    const toggleDropdown = (dropdownType) => {
        if (dropdownType in dropdownStates) {
            const stateSetter = {
                extrasConfiguration: setExtrasConfigurationDropdownOpen,
                status: setStatusDropdownOpen,
                testType: setTestTypeDropdownOpen,
                productGroup: setProductGroupDropdownOpen
            }[dropdownType];
            stateSetter(prevState => !prevState);
        }
    };

    const getSelectedTestTypesText = () => {
        return selectedTestTypes.length > 0 ? selectedTestTypes.join(", ") : "Select Test Type";
    };

    const handleExtrasConfigurationChange = (config) => {
        setSelectedExtrasConfiguration(prevSelectedExtras =>
            prevSelectedExtras.includes(config)
                ? prevSelectedExtras.filter(extra => extra !== config)
                : [...prevSelectedExtras, config]
        );
        setValue("configType", selectedExtrasConfiguration);
    };

    const handleTestTypeChange = (value) => {
        const updatedTestTypes = selectedTestTypes.includes(value)
            ? selectedTestTypes.filter(type => type !== value)
            : [...selectedTestTypes, value];
        setSelectedTestTypes(updatedTestTypes);
        setValue("testType", updatedTestTypes);
    };

    const handleProductGroupChange = (group) => {
        setSelectedProductGroup(group);
        setProductGroupDropdownOpen(false);
    };

    const clearSelectedTestTypes = () => {
        setSelectedTestTypes([]);
    };

    const productData = data?.[0] || {};

    const statusArray = Array.from(new Set(testConfiguration.flatMap((item) => item.product?.status || []))).map(
        (status) => status.charAt(0).toUpperCase() + status.slice(1)
    );

    const testArray = Array.from(
        new Set(
            testConfiguration.flatMap((item) => item.product?.testTypeData || []).map((type) => type.label)
        )
    );

    const productGroupArray = Array.from(new Set(testConfiguration.flatMap((item) => item.product?.productGroup || [])));
    const extraConfigArray = Array.from(new Set(extrasConfiguration.flatMap((item) => item.extraValue || [])));

    const onSubmit = async (data) => {
        const formValues = {
            id: data.productCode,
            productName: data.productName,
            testTypeData: selectedTestTypes.map((type) => ({
                label: type,
                value: type.charAt(0).toUpperCase(),
            })),
            extrasConfiguration: selectedExtrasConfiguration.length > 0 ? selectedExtrasConfiguration : null,
            status: data.status,
            productGroup: selectedProductGroup ?? null,
        };

        mutate(formValues);
    };


    if (isLoading) {
        return (
            <div className="d-flex justify-content-center align-items-center ">
                <Spinner size="sm" />
            </div>
        )
    }

    return (
        <form onSubmit={handleSubmit(onSubmit)}>
            {successMessage && <Alert color="success">{successMessage}</Alert>}
            {errorMessage && <Alert color="danger">{errorMessage}</Alert>}
            <Row>
                <Col md={6}>
                    <div className="mb-3">
                        <Label htmlFor="formrow-text-Input">Product Code *</Label>

                        {editing ? (
                            <Controller
                                name="productCode"
                                control={control}
                                defaultValue={productData?.id || ""}
                                render={({ field }) => (
                                    <input
                                        readOnly
                                        placeholder="Enter Product Code"
                                        {...field}
                                        className={`form-control ${errors.productCode ? "is-invalid" : ""}`}
                                        disabled
                                    />
                                )}
                            />
                        ) : (
                            <input
                                disabled={!editing || isSubmitted}
                                readOnly
                                type="text"
                                className="form-control"
                                value={productData?.id || ""}
                            />
                        )}

                        {errors.productCode && (
                            <div className="invalid-feedback">
                                {errors.productCode.message}
                            </div>
                        )}
                    </div>
                </Col>


                <Col className="mb-3" lg="6">
                    <Label className="form-label">Extras Configuration</Label>

                    {editing ? (
                        <Dropdown isOpen={extrasConfigurationDropdownOpen} toggle={() => toggleDropdown("extrasConfiguration")}>
                            <DropdownToggle
                                className="form-control text-start px-3"
                                disabled={isSubmitted}
                                style={{
                                    backgroundColor: "transparent",
                                    border: "1px solid #ccc",
                                    color: "#000",
                                    display: "flex",
                                    justifyContent: "space-between",
                                    alignItems: "center",
                                }}
                                caret
                            >
                                {selectedExtrasConfiguration.length > 0
                                    ? selectedExtrasConfiguration.join(", ")
                                    : "Select Extras Configuration"}
                            </DropdownToggle>
                            <DropdownMenu className="mt-2">
                                {extraConfigArray.map((extrasConfiguration) => (
                                    <DropdownItem key={extrasConfiguration} toggle={false} onClick={(e) => e.stopPropagation()}>
                                        <div className="form-check">
                                            <input
                                                type="checkbox"
                                                className="form-check-input"
                                                id={extrasConfiguration}
                                                value={extrasConfiguration}
                                                checked={selectedExtrasConfiguration.includes(extrasConfiguration)}
                                                onChange={() => handleExtrasConfigurationChange(extrasConfiguration)}
                                            />
                                            <label className="form-check-label cursor-pointer" htmlFor={extrasConfiguration}>
                                                {extrasConfiguration}
                                            </label>
                                        </div>
                                    </DropdownItem>
                                ))}
                            </DropdownMenu>
                        </Dropdown>
                    ) : (

                        <input
                            type="text"
                            className="form-control"
                            value={selectedExtrasConfiguration.length > 0
                                ? selectedExtrasConfiguration.join(", ")
                                : "Select Extras Configuration"}
                            readOnly
                            disabled
                        />
                    )}
                </Col>


                <Col md={6}>
                    <div className="mb-3">
                        <Label htmlFor="formrow-text-Input">Product Name *</Label>

                        {editing ? (

                            <Controller
                                name="productName"
                                control={control}
                                defaultValue={productData?.productName || ""}
                                render={({ field }) => (
                                    <input placeholder="Enter Product Name" {...field} className={`form-control ${errors.productName ? "is-invalid" : ""}`} disabled={isSubmitted} />
                                )}
                            />
                        ) : (

                            <input
                                type="text"
                                className="form-control"
                                value={productData?.productName || ""}
                                readOnly
                                disabled={!editing || isSubmitted}
                            />
                        )}
                    </div>
                    {errors.productName && <div className="invalid-feedback">{errors.productName.message}</div>}
                </Col>

                <Col md={6}>
                    <Label className="form-label">Status *</Label>

                    {editing ? (
                        <Controller
                            defaultValue={productData?.status || ""}
                            name="status"
                            control={control}
                            render={({ field }) => (
                                <Dropdown isOpen={statusDropdownOpen} toggle={() => toggleDropdown("status")}>
                                    <DropdownToggle
                                        className="form-control text-start px-3"
                                        style={{
                                            backgroundColor: "transparent",
                                            border: "1px solid #ccc",
                                            color: "#000",
                                            display: "flex",
                                            justifyContent: "space-between",
                                            alignItems: "center",
                                        }}
                                        caret
                                        disabled={isSubmitted}
                                    >
                                        {field.value || "Select Status"}
                                    </DropdownToggle>
                                    <DropdownMenu className="mt-2">
                                        {statusArray.map((status) => (
                                            <DropdownItem key={status} onClick={() => field.onChange(status)}>
                                                {status}
                                            </DropdownItem>
                                        ))}
                                    </DropdownMenu>
                                </Dropdown>
                            )}
                        />
                    ) : (

                        <input
                            type="text"
                            className="form-control"
                            value={selectedStatus || "Select Status"}
                            readOnly
                            disabled
                        />
                    )}
                </Col>

                <Col className="mb-3" lg="6">
                    <Label className="form-label">Test Type *</Label>

                    {editing ? (
                        <Dropdown isOpen={testTypeDropdownOpen} toggle={() => toggleDropdown("testType")}>
                            <DropdownToggle
                                className="form-control text-start px-3"
                                disabled={!editing || isSubmitted}

                                style={{
                                    backgroundColor: "transparent",
                                    border: "1px solid #ccc",
                                    color: "#000",
                                    display: "flex",
                                    justifyContent: "space-between",
                                    alignItems: "center",
                                }}
                                caret
                            >
                                <span style={{ flex: "1 1 auto", overflow: "auto", whiteSpace: "nowrap" }}>
                                    {getSelectedTestTypesText()}
                                </span>
                                {selectedTestTypes.length > 0 && (
                                    <FaTimes
                                        onClick={clearSelectedTestTypes}
                                        style={{
                                            cursor: "pointer",
                                            fontSize: "1rem",
                                            marginLeft: "1rem",
                                            color: "#888",
                                        }}
                                    />
                                )}
                            </DropdownToggle>
                            <DropdownMenu className="mt-2">
                                {testArray.map((testType) => (
                                    <DropdownItem key={testType} toggle={false} onClick={(e) => e.stopPropagation()}>
                                        <div className="form-check">
                                            <input
                                                type="checkbox"
                                                className="form-check-input"
                                                id={testType}
                                                name="testType"
                                                value={testType}
                                                checked={selectedTestTypes.includes(testType)}
                                                onChange={() => handleTestTypeChange(testType)}
                                            />
                                            <label className="form-check-label cursor-pointer" htmlFor={testType}>
                                                {testType}
                                            </label>
                                        </div>
                                    </DropdownItem>
                                ))}
                            </DropdownMenu>
                        </Dropdown>
                    ) : (
                        <input
                            type="text"
                            className="form-control"
                            value={getSelectedTestTypesText() || ""}
                            readOnly
                            disabled={!editing || isSubmitted}
                        />
                    )}
                </Col>


                <Col className="mb-3" lg="6">
                    <Label className="form-label">Product Group</Label>

                    {editing ? (
                        <Dropdown isOpen={productGroupDropdownOpen} toggle={() => toggleDropdown("productGroup")}>
                            <DropdownToggle
                                className={`form-control text-start px-3 ${errors.productGroup ? "is-invalid" : ""}`}
                                disabled={!editing || isSubmitted}
                                style={{
                                    backgroundColor: "transparent",
                                    border: "1px solid #ccc",
                                    color: "#000",
                                    display: "flex",
                                    justifyContent: "space-between",
                                    alignItems: "center",
                                }}
                                caret
                            >
                                {selectedProductGroup || "Select Product Group"}
                            </DropdownToggle>
                            <DropdownMenu className="mt-2">

                                <DropdownItem
                                    key="clear"
                                    onClick={() => handleProductGroupChange("")}
                                    style={{ color: "#999" }}
                                >
                                    Clear Selection
                                </DropdownItem>


                                {productGroupArray.map((group) => (
                                    <DropdownItem
                                        key={group}
                                        onClick={() => handleProductGroupChange(group)}
                                    >
                                        {group}
                                    </DropdownItem>
                                ))}
                            </DropdownMenu>
                        </Dropdown>
                    ) : (
                        <input
                            type="text"
                            className="form-control"
                            value={selectedProductGroup || "Select Product Group"}
                            readOnly
                            disabled
                        />
                    )}

                    {errors.productGroup && (
                        <Alert className="mt-1" color="danger">
                            {errors.productGroup.message}
                        </Alert>
                    )}
                </Col>


            </Row>
            <div className="d-flex justify-content-end gap-3 mt-4">
                {editing ? (
                    <>
                        <Button
                            key="update"
                            color="danger"
                            type="update"
                            disabled={isSubmitted || mutating}
                        >
                            Update
                            {mutating && <Spinner size="sm" className="ms-2" />}
                        </Button>

                        {!isSubmitted && (
                            <Button
                                key="cancel"
                                color="secondary"
                                type="button"
                                onClick={() => setEditing(false)}
                            >
                                Cancel
                            </Button>
                        )}
                    </>
                ) : (
                    <Button
                        key="edit"
                        color="primary"
                        type="button"
                        onClick={() => setEditing(true)}
                    >
                        Edit
                    </Button>
                )}
            </div>

        </form>
    );
};

export default ProductsDetails;
