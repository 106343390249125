import { axiosClient, useRequestProcessor } from "api/provider"
import { paramsToQuery } from "utils/query"

export function useRequisitionsQuery({ variables, ...rest }) {
  const { query } = useRequestProcessor()
  const fields = [
    `"_id"`,
    `"status"`,
    `"data.submittedTimestamp"`,
    `"data.firstName"`,
    `"data.lastName"`,
    `"data.dateOfBirth"`,
    `"data.sampleId"`,
    `"data.manualTestPrompt"`,
    `"data.destination"`,
    `"data.firstName"`,
    `"data.lastName"`,
    `"data.middleInitial"`,
    `"data.dateOfBirth"`,
    `"data.gender"`,
    `"data.country"`,
    `"data.state"`,
    `"data.zipCode"`,
    `"data.region"`,
    `"data.postalCode"`,
    `"data.address"`,
    `"data.city"`,
    `"data.phoneNumber"`,
    `"data.email"`,
    `"data.responsibleParty"`,
    `"data.relationshipToPatient"`,
    `"data.billTo"`,
    `"data.creditCardName"`,
    `"data.creditCardType"`,
    `"data.creditCardNo"`,
    `"data.creditCardExpiry"`,
    `"data.creditCardCVV"`,
    `"data.billingCountry"`,
    `"data.billingState"`,
    `"data.billingZipCode"`,
    `"data.billingRegion"`,
    `"data.billingPostalCode"`,
    `"data.billingAddress"`,
    `"data.providerFullName"`,
    `"data.providerNPINo"`,
    `"data.providerFacility"`,
    `"data.providerPhoneNo"`,
    `"data.providerEmail"`,
    `"data.providerAddressLine1"`,
    `"data.providerCity"`,
    `"data.providerState"`,
    `"data.providerZipCode"`,
    `"data.providerCountry"`,
    `"data.providerFaxNo"`,
    `"data.providerDiagnostic"`,
    `"data.undefined"`,
    `"data.providerEmailDelivery"`,
    `"data.providerFaxDelivery"`,
    `"data.providerLabel"`,
    `"data.testTypeName"`,
    `"data.bloodCollectionDate"`,
    `"data.questionName"`,
    `"data.isPreSurgery"`,
    `"data.isPostSurgery"`,
    `"data.postSurgeryMonths"`,
    `"data.isMonitoringWaiting"`,
    `"data.isMonitoringAnalog"`,
    `"data.lastSSADate"`,
    `"data.isSymptomsNotConfirmed"`,
    `"data.isMonitoringOther"`,
    `"data.isChemo"`,
    `"data.isTargetTherapy"`,
    `"data.isPRRT"`,
    `"data.isOther"`,
  ]

  const params = encodeURIComponent(`[${fields.toString()}]`)
  const newVariables = {
    ...variables,
    selector: variables?.selector ? { ...variables.selector } : {},
  }

  if (newVariables?.selector?.["data.firstName"]?.$eq) {
    newVariables.selector["data.firstName"] = {
      $regex: "(?i)" + newVariables.selector["data.firstName"].$eq,
    }
  }

  if (newVariables?.selector?.["data.lastName"]?.$eq) {
    newVariables.selector["data.lastName"] = {
      $regex: "(?i)" + newVariables.selector["data.lastName"].$eq,
    }
  }

  if (!Boolean(newVariables?.selector?.["status"]) || newVariables?.selector?.["status"] === "pending") {
    newVariables.selector.status = {
      $or: [{ $nin: ["rejected", "accepted", "done"] }, { $exists: false }],
    }
  }

  return query(
    ["requisition", newVariables],
    () => axiosClient.get(`/requisitions?${paramsToQuery(newVariables, { allowEmptyObject: true })}&fields=${params}`).then((res) => res.data),
    { ...rest },
  )
}

export function useGetSampleIdQuery({ variables, ...rest } = {}) {
  const { query } = useRequestProcessor()

  return query(["get-sample-id", variables?.id], () => axiosClient.get(`/netests`).then((res) => res.data), { ...rest })
}

export function useRequisitionQuery({ variables, ...rest } = {}) {
  const { query } = useRequestProcessor()

  return query(["requisition", variables?.id], () => axiosClient.get(`/requisition/${variables?.id}`).then((res) => res.data), { ...rest })
}
