import React, { useEffect, useRef, useState } from 'react';
import lookups from "../configuration/lookups";
import DataWow from "./data-wow";
import { makeApiCall } from "../api/generic-api";
import { Alert, Col, Row } from "reactstrap";
import pricesFullDefinition from "../configuration/prices-full-definition";

function PricesView(props) {
    const [alertState, setAlertState] = useState(null);
    const [alertBadMessage, setAlertBadMessage] = useState(null);
    const structure = pricesFullDefinition;


    useEffect( () => {
        getProducts();
    }, []);


    const getProducts = async () => {
        const products = await makeApiCall('get', '/api/products');
        products.body.map(product => {
            const existing = lookups.find(element => element.type == 'product' && element.key == product.id)
            if (!existing) {
                lookups.push({
                    _id: product.id,
                    type: 'product',
                    key: product.id,
                    value: product.productName,
                })
            }
        })
    }

    useEffect(() => {
        setTimeout(() => {
            setAlertState(null)
            setAlertBadMessage(null)
        }, 6000)
    }, [ alertState ]);


    const userSelector = {
        heading: {
            label: 'Filter',
            style: {display: 'inline-block', fontWeight: 800}
        },
        lookups: lookups,
        controlBlockStyle: {display: 'inline-block'},
        controls: [
            {
                label: 'Product',
                name: 'productId',
                type: 'select',
                style: {maxWidth:'170px'},
                groupStyle: {display: 'inline-block', marginLeft: '20px'},
                source: {
                    kind: 'lookup',
                    data: 'product'
                },
            },
            {
                label: 'Payer Type',
                name: 'payerType',
                type: 'select',
                source: {
                    kind: 'lookup',
                    data: 'payerType'
                },
                style: {maxWidth:'170px'},
                groupStyle: {display: 'inline-block', marginLeft: '20px'}
            },
            {
                label: 'Fee Code',
                name: 'feeCode',
                type: 'text',
                style: {maxWidth:'170px'},
                groupStyle: {display: 'inline-block', marginLeft: '20px'},
                maxlength: 10,
                pattern: '^[a-zA-Z0-9]+$',
                title: 'It only accepts alphanumeric code',
            },
            {
                label: 'Price',
                name: 'price',
                type: 'text',
                style: {maxWidth:'170px'},
                groupStyle: {display: 'inline-block', marginLeft: '20px'}
            },
        ]
    };

    const updatePrice = async (formState) => {
        const result = await makeApiCall('put', `/api/price`, formState);
        if (result.ok) {
            setAlertState('updated');
            setAlertBadMessage('');
            window.scrollTo(0, document.body.scrollHeight);
        } else {
            const detail = `Details: code ${result.statusCode}; ${result.error}`;
            setAlertState('bad');
            setAlertBadMessage(detail);
            window.scrollTo(0, document.body.scrollHeight);
        }

        return result;
    };

    const addPrice = async (formState) => {
        const result = await makeApiCall('post', `/api/price`, formState);
        if (result.ok) {
            setAlertState('added');
            setAlertBadMessage('');
            window.scrollTo(0, document.body.scrollHeight);
        } else {
            const detail = `Details: code ${result.statusCode}; ${result.error}`;
            setAlertState('bad');
            setAlertBadMessage(detail);
            window.scrollTo(0, document.body.scrollHeight);
        }

        return result;
    };

    const customRowActions = [
        {
            type: 'button',
            name: 'delete',
            label: 'Delete',
            color: 'secondary',
            action: 'delete',
            details: {
                endpoint: 'delete',
                idToken: ':id'
            }
        },
      ]

    return (
        <div>
            <Row style={{marginTop: '30px'}}>
                <Col sm="3">
                </Col>
                <Col sm="6" className="text-center">
                    <h3>Prices</h3>
                </Col>
                <Col sm="3">
                </Col>
            </Row>
            <DataWow
                className={'wide-container'}
                structure={structure}
                userSelector={userSelector}
                loadData={makeApiCall}
                lookups={lookups}
                update={updatePrice}
                customRowActions={customRowActions}
                setAlertState={setAlertState}
                submit={addPrice}
                showAddButton={true}
                {...props} />

            { alertState === 'added' ?
              <Alert style={{marginTop: '10px', textAlign: 'center'}} color="success">
                  {'Price has been successfully added'}
              </Alert>
              : null
            }

            { alertState === 'updated' ?
              <Alert style={{marginTop: '10px', textAlign: 'center'}} color="success">
                  {'Price has been successfully updated'}
              </Alert>
              : null
            }

            { alertState === 'deleted' ?
              <Alert style={{marginTop: '10px', textAlign: 'center'}} color="success">
                  {'Price has been successfully deleted'}
              </Alert>
              : null
            }

            { alertState === 'bad' ?
              <Alert style={{marginTop: '10px', textAlign: 'center'}}  color="danger">
                  {'There was a problem submitting your data.'}
                  <br/>
                  { alertBadMessage || 'Please try again or contact us.'}
              </Alert>
              : null
            }
        </div>
    );
}

export default PricesView;
