import React, { useEffect, useRef, useState } from 'react';
import lookups from "../configuration/lookups";
import DataWow from "./data-wow";
import { makeApiCall } from "../api/generic-api";
import { Alert, Col, Row } from "reactstrap";
import insuranceCompanyFullDefinition from "../configuration/insurance-company-full-definition";

function InsuranceCompanyView(props) {
    const [alertState, setAlertState] = useState(null);
    const [alertBadMessage, setAlertBadMessage] = useState(null);
    const structure = insuranceCompanyFullDefinition;


    useEffect(() => {
        setTimeout(() => {
            setAlertState(null)
            setAlertBadMessage(null)
        }, 6000)
    }, [ alertState ]);


    const userSelector = {
        heading: {
            label: 'Filter',
            style: {display: 'inline-block', fontWeight: 800}
        },
        lookups: lookups,
        controlBlockStyle: {display: 'inline-block'},
        controls: [
            {
                label: 'Company Code',
                name: '_id',
                type: 'text',
                style: {maxWidth:'170px'},
                groupStyle: {display: 'inline-block', marginLeft: '20px'}
            },
            {
                label: 'Company Name',
                name: 'name',
                type: 'text',
                style: {maxWidth:'170px'},
                groupStyle: {display: 'inline-block', marginLeft: '20px'}
            },
        ],
        selectorFunction: (selector, controlState) => {
            let resultSelector = JSON.parse(JSON.stringify(selector));

            if (controlState['name']) {
                resultSelector = Object.assign({}, {
                    ...resultSelector.data,
                    name: {
                        $regex: "(?i)" +controlState['name']
                    }
                })
            };

            if (controlState['_id']) {
                resultSelector = Object.assign({},   {
                    ...resultSelector.data,
                        _id: {
                        $regex: "(?i)" +controlState['_id']
                    }
                })
            };


            return resultSelector;
        }
    };

    const updateInsuranceCompany = async (formState) => {
        if (formState?.companyId) {
            formState.companyId = formState.companyId.toUpperCase();
        }

        const result = await makeApiCall('put', `/api/insurance-company`, formState);
        if (result.ok) {
            setAlertState('updated');
            setAlertBadMessage('');
            window.scrollTo(0, document.body.scrollHeight);
        } else {
            const detail = `${result.error}`;
            setAlertState('bad');
            setAlertBadMessage(detail);
            window.scrollTo(0, document.body.scrollHeight);
        }

        return result;
    };


    const addInsuranceCompany = async (formState) => {
        if (formState?.companyId) {
            formState.companyId = formState.companyId.toUpperCase();
        }

        const result = await makeApiCall('post', `/api/insurance-company`, formState);
        if (result.ok) {
            setAlertState('added');
            setAlertBadMessage('');
            window.scrollTo(0, document.body.scrollHeight);
        } else {
            const detail = `${result.error}`;
            setAlertState('bad');
            setAlertBadMessage(detail);
            window.scrollTo(0, document.body.scrollHeight);
        }

        return result;
    };

    const customRowActions = [
        {
            type: 'button',
            name: 'delete',
            label: 'Delete',
            color: 'secondary',
            action: 'delete',
            details: {
                endpoint: 'delete',
                idToken: ':id'
            }
        },
      ]

    const fetchProducts = async () => {
        const products = await makeApiCall('get', '/api/products/');
        products.body.map(product => {
            const existing = lookups.find(element => element.type === 'product' && element.key === product.id);
            if (!existing) {
                lookups.push({
                    _id: product.id,
                    type: 'product',
                    key: product.id,
                    value: product.productName
                });
            }
        });
    };

    useEffect(() => {
        fetchProducts();
    }, []);

    return (
        <div>
            <Row style={{marginTop: '30px'}}>
                <Col sm="3">
                </Col>
                <Col sm="6" className="text-center">
                    <h3>Insurance Companies</h3>
                </Col>
                <Col sm="3">
                </Col>
            </Row>
            <DataWow
                className={'wide-container'}
                structure={structure}
                userSelector={userSelector}
                loadData={makeApiCall}
                lookups={lookups}
                update={updateInsuranceCompany}
                customRowActions={customRowActions}
                setAlertState={setAlertState}
                submit={addInsuranceCompany}
                showAddButton={true}
                {...props} />

            { alertState === 'added' ?
              <Alert style={{marginTop: '10px', textAlign: 'center'}} color="success">
                  {'Insurance company has been successfully added'}
              </Alert>
              : null
            }

            { alertState === 'updated' ?
              <Alert style={{marginTop: '10px', textAlign: 'center'}} color="success">
                  {'Insurance company has been successfully updated'}
              </Alert>
              : null
            }


            { alertState === 'deleted' ?
              <Alert style={{marginTop: '10px', textAlign: 'center'}} color="success">
                  {'Insurance company has been successfully deleted.'}
              </Alert>
              : null
            }
            { alertState === 'bad' ?
              <Alert style={{marginTop: '10px', textAlign: 'center'}}  color="danger">
                  {'There was a problem submitting your data.'}
                  <br/>
                  { alertBadMessage || 'Please try again or contact us.'}
              </Alert>
              : null
            }
        </div>
    );
}

export default InsuranceCompanyView;
