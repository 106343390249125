import React, { useMemo, useState } from "react"
import { Link } from "react-router-dom"
import { useProcessSamplesQuery } from "api/getters"
import dayjs from "dayjs"
import utc from "dayjs/plugin/utc"
import TableContainer from "./TableContainer"
import { useTableQueryStore } from "stores/zustand/tableQueryStore"
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap"
import useDisclosure from "hooks/disclosure"
import { TransactionHistoryTable } from "./TransactionHistoryTable"
import { ModalTitle } from "react-bootstrap"
dayjs.extend(utc)

export function ProcessSamplesTable({ customSelect = {}, authentication, pageSize: pageSizeProp, ...rest }) {
    const permission = authentication?.permission;
  const { page, pageSize, selector, sort } = useTableQueryStore()
  const {
    data: samplesData,
    isLoading,
    isFetching,
    refetch,
  } = useProcessSamplesQuery({
    variables: {
      sort,
      page,
      selector: {
        ...selector,
        ...customSelect,
      },
      pageSize: pageSizeProp || pageSize,
    },
  })

    const hasRole = (role) => {
        const roles = authentication?.roles;
        return roles && roles.length && roles.includes(role);
    };

  const [sampleId, setSampleId] = useState(null)
  const { openModal, closeModal, toggleModal, isOpen } = useDisclosure()

  const columns = useMemo(
    () => [
      {
        Header: "Sample ID",
        accessor: "id",
        hasSort: true,
        csvAccessor: "csvId",
      },
      {
        Header: "Patient ID",
        accessor: "subjectId",
        hasSort: true,
        csvAccessor: "csvSubjectId",
      },
      {
        Header: "Collected",
        accessor: "sampleCollectionDateTime",
        hasSort: true,
      },
      {
        Header: "Accessioned",
        accessor: "laboratoryReceivedDateTime",
        hasSort: true,
      },
      {
        Header: "Collection Method",
        accessor: "collectionMethod",
        hasSort: true,
      },
      {
        Header: "Status",
        accessor: "status",
        hasSort: true,
      },
      {
        Header: "Action",
        accessor: "action",
        skipCsv: true,
      },
    ],
    [],
  )

  const data = useMemo(
    () =>
      (samplesData &&
        samplesData.map((item) => ({
          id: item.id,
          csvId: item.id,
          subjectId: (
            <Link to={`/subject-info/${item.subjectId}/personal`} className="text-secondary" target="_blank">
              {item.subjectId}
            </Link>
          ),
          csvSubjectId: item.subjectId,
          sampleCollectionDateTime: item?.sampleCollectionDateTime && dayjs(item?.sampleCollectionDateTime).format("MM/DD/YYYY"),
          laboratoryReceivedDateTime: item?.laboratoryReceivedDateTime && dayjs(item?.laboratoryReceivedDateTime).format("MM/DD/YYYY"),
          collectionMethod: item?.collectionMethod,
          status: item?.status,
          action: (permission === "administrator" || hasRole("laboratory" || hasRole("laboratoryDirector"))) && (
            <div className="d-flex gap-2">
              <Link to={`/process-sample/${item.id}`} className="btn btn-outline-primary">
                Process
              </Link>
              <Button
                outline
                color="secondary"
                onClick={() => {
                  setSampleId(item.id)
                  openModal()
                }}
              >
                Audit
              </Button>
            </div>
          ),
        }))) ||
      [],
    [samplesData],
  )
  const filters = useMemo(() => {
    return [
      {
        field: "_id",
        operator: "$eq",
        label: "Search Sample ID",
        placeholder: "Type in Sample ID",
        removeSpaces: true,
      },
      {
        field: "subjectId",
        operator: "$eq",
        label: "Search Patient ID",
        placeholder: "Type in Patient ID",
        removeSpaces: true,
      },
      {
        field: "laboratoryReceivedDateTime",
        operator: "$gte",
        label: "Accession Date",
        type: "date",
      },
    ]
  }, [])

  return (
    <div>
      <TableContainer
        refetch={refetch}
        columns={columns}
        data={data}
        isLoading={isLoading || isFetching}
        filters={filters}
        selector={selector}
        sort={sort}
        defaultSort="sampleCollectionDateTime"
        defaultSortDir="desc"
        downloadFileName="Process Samples"
        {...rest}
      />
      <Modal isOpen={isOpen} toggle={toggleModal} backdrop={true} size="xl">
        <ModalHeader>
          <ModalTitle>Transaction History</ModalTitle>
        </ModalHeader>
        <ModalBody>
          <TransactionHistoryTable type="sample" id={sampleId} customPageSize={1000} />
        </ModalBody>
        <ModalFooter>
          <Button type="button" outline color="secondary" onClick={closeModal}>
            Close
          </Button>
        </ModalFooter>
      </Modal>
    </div>
  )
}
