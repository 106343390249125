import insuranceCompanyFormDefinition from "./insurance-company-form-definition";

const insuranceCompanyFullDefinition = {
    key: '_id',
    table: {
        key: '_id',
        columns: [
            {
                label: '#',
                type: 'rowNumber',
                filterType: 'none',
                sortType: 'none'
            },
            {
                label: 'Company Code',
                field: 'id',
                type: 'text',
                filterType: 'equals'
            },
            {
                label: 'Name',
                field: 'name',
                type: 'text',
                filterType: 'equals'
            },
            {
                label: 'Products',
                field: 'products',
                type: 'text',
                filterType: 'equals'
            },
            {
                label: 'Notes',
                field: 'notes',
                type: 'text',
                filterType: 'equals',
            },
        ],
    },
    
    form: insuranceCompanyFormDefinition,
    endpoints : {
        search: {
            uri: '/api/insurance-companies?',
            verb: 'get'
        },
        delete: {
            uri: '/api/insurance-company/:id',
            verb: 'delete'
        },
        
    },
    pageSize: 10
};

export default insuranceCompanyFullDefinition;
