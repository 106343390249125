import React, { useEffect, useRef, useState } from 'react';
import {Row, Col} from 'reactstrap';
import FileUploader from '../file-uploader';
import DataWow from "../data-wow";
import {makeApiCall} from "../../api/generic-api";
import lookups from "../../configuration/lookups";
import templateFullDefinition from "../../configuration/template-full-definition";
import ReactLoading from "react-loading";
import DataWowTemplates from "../data-wow-templates";
import { useAlert } from "react-alert";
import requisitionsConfigFullDefinition from "../../configuration/requisitions-configuration-full-definition";
import DataWowRequisitionsConfiguration from "./data-wow-requisitions-configuration";

function RequisitionsConfigurationView(props) {
	const [ testConfiguration, setTestConfiguration ] = useState([]);
	const [ loading, setLoading ] = useState(false);
	
	const structure = requisitionsConfigFullDefinition;
	const childRef = useRef(null);
	const alert = useAlert()
	
	useEffect(() => {
		getTestConfiguration();
	}, []);
	
	const getTestConfiguration = async () => {
		setLoading(true)
		const result = await makeApiCall('get', `/api/test-configuration`);
		
		const activeProducts = result.body.filter(item => item?.product?.status == 'active');
		setTestConfiguration(activeProducts)
		setLoading(false);
	};
	
	
	const customRowActions = [
		{
			type: 'button',
			label: 'Publish',
			color: 'warning',
			style: {color: 'black'},
			details: {
				download: false,
				endpoint: 'publish',
				idToken: ':id',
				
			}
		}
	];
	
	const updateTemplate = async (formState) => {
		
		const result = await makeApiCall('post', `/api/requisitions-configuration`, _.omitBy(formState, (v) => !v));
		if (result.ok) {
			alert.show("Successfully updated", {
				type: 'success'
			})
		} else {
			const details = `Details: code ${result.statusCode}; ${result.error}`;
			alert.show(details, {
				type: 'error'
			})
		}

		return result;
	};
	
	return (
		<div>
			<h3 className="text-center mt-5">Requisitions Configuration</h3>
			{ loading &&
				<center className="mt-5">
					<ReactLoading type='spin' color='red' height={667} width={100} />
				</center>
			}
			
			{!loading && testConfiguration.length &&
				<DataWowRequisitionsConfiguration
					className={'wide-container'}
					structure={structure}
					loadData={makeApiCall}
					lookups={lookups}
					ref={childRef}
					customRowActions={customRowActions}
					// showViewFormAction={true}
					showPaginationButtons={true}
					update={updateTemplate}
					submit={updateTemplate}
					showAddButton={true}
					formSource = {
						[
							{
								name: 'testType',
								data: testConfiguration
							}
						]
					}
					{...props} />
			}
			
		
		</div>
	);
}

export default RequisitionsConfigurationView;
