import React, {useEffect, useMemo, useState} from "react"
import { usePcrListQuery } from "api/getters"
import dayjs from "dayjs"
import { Link } from "react-router-dom"
import TableContainer from "./TableContainer"
import { useTableQueryStore } from "stores/zustand/tableQueryStore"
import { useQuery } from "hooks/query"
import { Button, Spinner } from "reactstrap"
import { useAlert } from "react-alert"
import { getEncodedQuery } from "utils/query"
import { makeApiDownloadCall } from "api/generic-api"
import ResultUploadDetails from "components/custom/ResultUploadDetails"
import ProcessCancerPrcInnerFormNew from "components/process-cancer-pcr-inner-form-new"

export function ResultsUploadTable({ type = "cancer", pageSize: pageSizeProp, customSelect = {}, tableKey, downloadFileName = "", ...rest }) {
  const alert = useAlert()
  const query = useQuery()
  const { page, pageSize, selector, sort } = useTableQueryStore()

  const [downloadId, setDownloadId] = useState(false)
  const [downloading, setDownloading] = useState(false)

  const {
    data: pcrListData,
    isLoading,
    isFetching,
    refetch,
    remove,
  } = usePcrListQuery({
    refetchInterval: 3000,
    variables: {
      sort,
      page,
      selector: {
        ...selector,
        ...customSelect,
      },
      pageSize: pageSizeProp || pageSize,
    },
  })

  useEffect(() => {
    return () => {
      remove()
    }
  }, [])

  const columns = useMemo(
    () => [
      {
        Header: "Submitted",
        accessor: "submittedTimestamp",
        hasSort: true,
      },
      {
        Header: "Author",
        accessor: "authorShort",
        hasSort: true,
      },
      {
        Header: "File name",
        accessor: "fileName",
        hasSort: true,
      },
      {
        Header: "Processing Result",
        accessor: "results.fileResult",
        csvAccessor: "fileResult",
        hasSort: true,
      },
      {
        Header: "Test Upload",
        accessor: "testing",
        hasSort: true,
      },
      {
        Header: "Actions",
        accessor: "actions",
        skipCsv: true,
      },
    ],
    [],
  )

  const activePcr = pcrListData?.find((item) => item?.id === query.get("id"))

  const downloadReport = async (_pcr) => {
    try {
      setDownloadId(_pcr?.id)
      setDownloading(true)
      const response = await makeApiDownloadCall(
        "get",
        `/api/document/pcr-received/${_pcr?.id}-${_pcr?.fileName}`,
        {},
        "application/pdf",
        `${_pcr?.fileName}`,
        // true,
      )
      const activeDownload = pcrListData?.find((item) => item?.id === _pcr?.id)

      if (!response?.ok) {
        alert.show(`${activeDownload?.fileName} is not yet available`)
      }
    } catch (error) {
      console.error(error)
    } finally {
      setDownloading(false)
    }
  }

  const data = useMemo(
    () =>
      (pcrListData &&
        pcrListData?.map((item) => ({
          submittedTimestamp: dayjs(item?.submittedTimestamp).format("MM/DD/YYYY"),
          authorShort: item.authorShort,
          fileName: item.fileName,
          results: {
            fileResult: item.results?.fileResult,
          },
          fileResult: item.results?.fileResult,
          testing: item.testing ? "Yes" : "No",
          actions: (
            <div className="d-flex gap-2 text-nowrap">
              <Link to={`/process-${type}-pcr?${getEncodedQuery({ id: item.id, testId: "" }, "", query.toString())}`} className="btn btn-outline-primary">
                View
              </Link>
              <Button color="primary" disabled={downloadId === item?.id && downloading} onClick={() => downloadReport(item)}>
                {downloadId === item?.id && downloading ? <Spinner size="sm" /> : <i className="fa fa-download" />}
              </Button>
            </div>
          ),
        }))) ||
      [],
    [pcrListData, downloadId, downloading],
  )

  if (query.get("testId")) {
    const currentRecord = { testId: query.get("testId") }
    return (
      <div>
        <div className="mb-4">
          <Link
            to={
              query.get("id")
                ? `/process-${type}-pcr?${getEncodedQuery({ testId: "", id: query.get("id") }, "", query.toString())}`
                : window.history.length > 2
                ? -1
                : `/process-${type}-pcr`
            }
            className="btn btn-primary"
          >
            <i className="fa fa-caret-left" /> Go Back
          </Link>
        </div>
        <div>
          <ProcessCancerPrcInnerFormNew currentInnerRecord={currentRecord} {...rest} />
        </div>
      </div>
    )
  }

  if (query?.get("id")) {
    return (
      <div>
        <ResultUploadDetails type={type} data={activePcr} {...rest} />
        {isLoading && (
          <center>
            <Spinner type="grow" />
          </center>
        )}
      </div>
    )
  }

  return (
    <div>
      <TableContainer
        downloadFileName={downloadFileName}
        refetch={refetch}
        columns={columns}
        data={data}
        isLoading={isLoading}
        filters={[]}
        selector={selector}
        sort={sort}
        defaultSortDir="desc"
        {...rest}
      />
    </div>
  )
}
