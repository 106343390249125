import React, { useEffect, useMemo, useState } from "react"
import { Card, Row, Col, Container, CardBody, CardTitle, CardHeader, Button } from "reactstrap"
import { Route, Routes, useParams } from "react-router-dom"
import { SidebarPatientInfo } from "./SidebarPatientInfo"
import { usePatientDetailsQuery, usePatientOrdersQuery, usePatientTestsQuery } from "api/getters"
import Breadcrumb from "components/custom/Breadcrumb"
import PatientDetails from "./PatientDetails"
import TestDetails from "./TestDetails"
import OrderDetails from "./OrderDetails"
import { PrivateRoute } from "components/nav-routing"
import { usePreviousTableQuery, useQuery } from "hooks/query"
import utc from "dayjs/plugin/utc"
import dayjs from "dayjs"
import { DuplicatesTable } from "components/tables/DuplicatesTable"
dayjs.extend(utc)

const PatientInfoView = ({ ...props }) => {
  const permission = props && props.applicationState.authentication.user.permission

  const query = useQuery()
  const previousTableQuery = usePreviousTableQuery("/subject-view")
  const { subjectId, "*": file, item } = useParams()

  const { data: patient = {}, refetch } = usePatientDetailsQuery({ variables: { subjectId } })
  const { data: patientTests, isFetching: loadingTests, refetch: refetchTests } = usePatientTestsQuery({ variables: { patientId: subjectId } })
  const { data: patientOrders, isFetching: loadingOrders, refetch: refetchOrders } = usePatientOrdersQuery({ variables: { patientId: subjectId } })
  const [sample, selectSample] = useState("")

  const id = query.get("id")
  const product = query.get("product")

  const loadingStatus = {
    tests: loadingTests,
    orders: loadingOrders,
  }

  const patientName = `${patient.firstName} ${patient.lastName}`

  const breadcrumbItems = useMemo(() => {
    return [
      {
        label: "Patients",
        link: `/subject-view?${previousTableQuery}`,
      },
      {
        label: patient?.firstName ? patientName : subjectId,
        link: `/subject-info/${subjectId}/personal`,
      },
    ]
  }, [subjectId, file, id, item, patientName])

  const handleMergeSuccess = () => {
    refetch()
    refetchTests()
    refetchOrders()
  }

  useEffect(() => {
    selectSample("")
  }, [id, item])

  const title = useMemo(() => {
    if (id) {
      if (file === "covid") {
        return "COVID Test Result"
      }

      if (file === "cancer") {
        return "Cancer Test Result"
      }

      if (file === "orders") {
        return "Order Information"
      }
    }

    return "Patient's Information"
  }, [file, id])

  return (
    <>
      <Breadcrumb title={``} breadcrumbItems={breadcrumbItems} />
      {Boolean(patient?.id) && (
        <Row className="mb-3">
          <Col>
            <Card>
              <CardBody>
                <div className="d-flex flex-wrap gap-3">
                  <div className="flex-grow-1 align-self-center">
                    <div className="text-muted">
                      <h2>
                        {patient.firstName} {patient.lastName}
                      </h2>
                      <p className="mb-1">{patient.email}</p>
                      <p className="mb-1">
                        {dayjs(patient?.dateOfBirth || patient?.dateOfBirth)
                          .utc()
                          .format("l")}
                      </p>
                      <p className="mb-0">
                        <b>{patient.id}</b>
                      </p>
                    </div>
                  </div>
                </div>
              </CardBody>
            </Card>
          </Col>
        </Row>
      )}
      <div className="d-xl-flex">
        <div className="w-100">
          <div className="d-md-flex">
            <SidebarPatientInfo
              file={file}
              activeId={id}
              sample={sample}
              activeProductId={product}
              patientId={subjectId}
              loading={loadingStatus}
              tests={patientTests || []}
              orders={patientOrders || []}
              selectSample={selectSample}
            />
            <div className="w-100 mb-3">
              <Row>
                <Col>
                  {sample ? (
                    <Card>
                      <CardHeader>
                        <CardTitle>Search by sample ID result:</CardTitle>
                      </CardHeader>
                      <CardBody>
                        <TestDetails key={sample} testId={sample} patientId={subjectId} permission={permission} {...props} />
                      </CardBody>
                    </Card>
                  ) : (
                    <>
                      <Card>
                        <CardHeader>
                          <CardTitle>{title}</CardTitle>
                        </CardHeader>
                        <CardBody>
                          {!Boolean(file) || !Boolean(id) ? (
                            <PatientDetails key={subjectId} id={subjectId} permission={permission} />
                          ) : (
                            <Routes>
                              <Route
                                path="personal"
                                exact={true}
                                element={<PrivateRoute component={PatientDetails} {...props} id={subjectId} permission={permission} />}
                              ></Route>
                              <Route
                                path="covid"
                                exact={true}
                                element={<PrivateRoute component={TestDetails} {...props} key={id} testId={id} patientId={subjectId} permission={permission} />}
                              ></Route>
                              <Route
                                path="cancer"
                                exact={true}
                                element={<PrivateRoute component={TestDetails} {...props} key={id} testId={id} patientId={subjectId} permission={permission} />}
                              ></Route>
                              {/* <Route path="netest-details" exact={true} element={<PrivateRoute component={ProcessCancerPcrView} {...props} />}></Route> */}
                              <Route
                                path="orders"
                                exact={true}
                                element={
                                  <PrivateRoute
                                    component={OrderDetails}
                                    {...props}
                                    key={id}
                                    id={id}
                                    data={patientOrders?.find((item) => item.id === id)}
                                    permission={permission}
                                    productId={product || ""}
                                  />
                                }
                              ></Route>
                            </Routes>
                          )}
                          {permission === "administrator" && (file === "personal" || !Boolean(id)) && (
                            <>
                              <hr className="mb-2 mt-5" />
                              <div>
                                <h5>Possible Patient Matches</h5>
                                <DuplicatesTable
                                  firstName={patient?.firstName}
                                  lastName={patient?.lastName}
                                  patientId={patient?.id}
                                  customPageSize={1000}
                                  onMergeSuccess={handleMergeSuccess}
                                />
                              </div>
                            </>
                          )}
                        </CardBody>
                      </Card>

                      {/* {!Boolean(id) && (
                          <Card>
                            <CardHeader>
                              <CardTitle>Patient's Details</CardTitle>
                            </CardHeader>
                            <CardBody>
                              <PatientDetails id={patientId} setPatientName={setPatientName} />
                            </CardBody>
                          </Card>
                        )}
                        {Boolean(id) && (file === "covid" || file === "cancer") && (
                          <Card>
                            <CardHeader>
                              <CardTitle>Test Details</CardTitle>
                            </CardHeader>
                            <CardBody>
                              <TestDetails key={id} testId={id} patientId={patientId} />
                            </CardBody>
                          </Card>
                        )}
                        {Boolean(id) && file === "orders" && (
                          <Card>
                            <CardHeader>
                              <CardTitle>Order Details</CardTitle>
                            </CardHeader>
                            <CardBody>
                              <OrderDetails id={id} data={patientOrders?.find((item) => item.id === id)} />
                            </CardBody>
                          </Card>
                        )} */}
                    </>
                  )}
                </Col>
              </Row>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default PatientInfoView
