import notificationFormDefinition from "./notification-form-definition";

const notificationFullDefinition = {
    key: 'id',
    table: {
        key: 'id',
        columns: [
            {
                label: '#',
                type: 'rowNumber',
                filterType: 'none',
            },
            {
                label: 'Title',
                field: 'title',
                type: 'text',
                filterType: 'equals',
                sortType: 'none',
                maxlength: 45
            },
            {
                label: 'Message',
                field: 'message',
                type: 'text',
                filterType: 'equals',
                sortType: 'none',
                maxlength: 45
            },
            {
                label: 'Created At',
                field: 'createdAt',
                type: 'timestamp',
                format: 'toLocalizedDateString',
                sortType: 'both'
            },
            {
                label: 'Updated At',
                field: 'updatedAt',
                type: 'timestamp',
                format: 'toLocalizedDateString',
                sortType: 'both'
            },
        ],
        defaultSort : [
            { createdAt : 'desc' }
        ]
    },

    form: notificationFormDefinition,
    // innerForm: pcrInnerFormDefinition,

    endpoints : {
        search: {
            uri: '/api/notifications?',
            verb: 'get'
        },
        delete: {
            uri: '/api/notifications/:id',
            verb: 'delete'
        },
    },
    pageSize: 10
};

export default notificationFullDefinition;
